import React, { useContext, useEffect, useMemo, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Typography,
  IconButton,
  TextField,
  DialogActions,
  Button,
  Stack,
  Grid,
  Box,
  Autocomplete,
  MenuItem,
  DialogContent,
  DialogTitle,
  Dialog,
  Select,
  FormControl,
  Tooltip,
  Avatar,
  FormHelperText,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import moment from "moment";
import imageCompression from "browser-image-compression";
//date picker
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//Icons
import { BiMinusCircle } from "react-icons/bi";
import { PiPlusThin } from "react-icons/pi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import CountryName from "../../Assets/countries.json";
import { SelectPosition } from "../Include/DynamicSelect";
import EmptyImage from "../../Assets/empty-image.png";
import CropImageFile from "../CropImage/CropImageFile";
import ListReference from "./ListReference";
import { UPDATE_SHAREHOLDER } from "../../Schema/Shareholder";

export default function UpdateShareholder({
  handleClose,
  setRefetch,
  editData,
  open,
}) {
  // console.log("editData+++", editData);
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [positionSelectValue, setPositionSelectValue] = useState({
    title: "",
    id: "",
  });

  // ========================= upload Image =========================
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = async (e) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    var newFile = new File([compressedFile], `${imageFile.name}.png`, {
      type: "image/png",
    });

    if (newFile) {
      setImageFile(newFile);
      setPhotoURL(URL.createObjectURL(newFile));
      setOpenCrop(true);
    }
  };

  //===============================START REFERENCE FILE LIST==================================
  const [requireField, setRequireField] = useState(false);
  const [currentReferenceFileImage, setCurrentReferenceFileImage] = useState({
    referenceFileImage: "",
    key: Date.now(),
  });

  const [referenceFileImageList, setReferenceFileImageList] = useState([]);

  const addReferenceFileImage = () => {
    let newReferenceFileImage = currentReferenceFileImage;
    if (newReferenceFileImage !== 0) {
      const items = [...referenceFileImageList, newReferenceFileImage];
      setReferenceFileImageList([...items]);
      setCurrentReferenceFileImage({
        referenceFileImage: "",
        key: "",
      });
    }
  };

  const handleAddReferenceFileImage = () => {
    setCurrentReferenceFileImage({
      referenceFileImage: "",
      key: Date.now(),
    });
    // addReferenceFileImage();
  };

  useEffect(() => {
    if (currentReferenceFileImage?.key !== "") {
      addReferenceFileImage();
    }
  }, [currentReferenceFileImage]);

  const setUpdateReferenceFileImage = (referenceFileImage, key) => {
    let items = referenceFileImageList;
    items?.map((i) => {
      if (i.key === key) {
        i.referenceFileImage = referenceFileImage;
      }

      if (referenceFileImage) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setReferenceFileImageList([...items]);
  };

  const deleteReferenceFileImageList = (key) => {
    let filterReferenceFileImage = referenceFileImageList?.filter(
      (t) => t?.key !== key
    );
    setReferenceFileImageList(filterReferenceFileImage);
  };

  //===============================END REFERENCE FILE LIST==================================

  const [updateShareholder] = useMutation(UPDATE_SHAREHOLDER, {
    onCompleted: ({ updateShareholder }) => {
      // console.log("updateShareholder::", updateShareholder)
      setLoading(false);
      if (updateShareholder?.status === true) {
        setAlert(true, "success", updateShareholder?.message);
        handleClose();
        setRefetch();
        resetForm();
        setPositionSelectValue({ id: "", title: "" });
        setReferenceFileImageList([]);
        setCurrentReferenceFileImage({
          referenceFileImage: "",
          key: "",
        });
      } else {
        setAlert(true, "error", updateShareholder?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    firstName: Yup.string().required("ទាមទារ"),
    lastName: Yup.string().required("ទាមទារ"),
    latinName: Yup.string().required("ទាមទារ"),
    gender: Yup.string(),
    nationalId: Yup.string(),
    tel: Yup.string(),
    remark: Yup.string(),
    referenceFiles: Yup.string(),
    position: Yup.string().required("ទាមទារ"),
    placeOfBirth: Yup.string(),
    dateOfBirth: Yup.string(),
    nationality: Yup.string(),
    email: Yup.string(),
    currentAddress: Yup.string(),
  });

  const formik = useFormik({
    initialValues: {
      tel: "",
      remark: "",
      position: "",
      placeOfBirth: "",
      nationality: "",
      nationalId: "",
      latinName: "",
      lastName: "",
      gender: "ប្រុស",
      firstName: "",
      email: "",
      dateOfBirth: moment(),
      currentAddress: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      let createStatus = false;
      const BreakException = {};
      try {
        if (referenceFileImageList?.length !== 0) {
          referenceFileImageList?.forEach(function (elem) {
            if (
              elem?.referenceFileImage !== undefined &&
              elem?.referenceFileImage !== ""
            ) {
              createStatus = true;
            } else {
              setLoading(false);
              setRequireField(true);
              createStatus = false;
              throw BreakException;
            }
          });
        } else {
          setLoading(false);
          setRequireField(true);
          createStatus = true;
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }
      // console.log("values::", values);

      // if (createStatus) {
      let referenceFileImage = [];
      referenceFileImageList?.map((item) => {
        const allFiles = item?.referenceFileImage;
        referenceFileImage?.push(allFiles);
      });

      setLoading(true);
      updateShareholder({
        variables: {
          id: editData?._id,
          input: {
            ...values,
            profileImage: profileHook ? profileHook : editData?.profileImage,
            referenceFiles: "",
          },
        },
      });
      // }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  useEffect(() => {
    if (positionSelectValue?.id) {
      setFieldValue("position", positionSelectValue?.id);
    } else {
      setFieldValue("position", "");
    }
  }, [positionSelectValue?.id, open]);

  useEffect(() => {
    if (editData) {
      setFieldValue("firstName", editData?.firstName);
      setFieldValue("lastName", editData?.lastName);
      setFieldValue("latinName", editData?.latinName ?? "");
      setFieldValue("gender", editData?.gender);
      setFieldValue("nationalId", editData?.nationalId);
      setFieldValue("tel", editData?.tel);
      setFieldValue("nationality", editData?.nationality);
      setFieldValue("email", editData?.email);
      setFieldValue("currentAddress", editData?.currentAddress);
      setFieldValue("placeOfBirth", editData?.placeOfBirth);
      setFieldValue("remark", editData?.remark ?? "");
      setFieldValue("position", editData?.positionId);
      setPositionSelectValue({
        id: editData?.positionId,
        title: editData?.position,
      });
    }
  }, [open]);

  useEffect(() => {
    if (editData?.referenceFiles?.length !== 0 && open) {
      let rows = [];
      editData?.referenceFiles?.map((d, index) => {
        // console.log("d::", d)
        const allRows = {
          key: index + new Date(),
          referenceFileImage: d,
        };
        rows.push(allRows);
      });
      setReferenceFileImageList(rows);
    }
  }, [open]);

  return (
    <Dialog open={open} className="dialog-container" maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              កែប្រែម្ចាស់ភាគហ៊ុន
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box>
                    <Stack direction="row" justifyContent="center">
                      <Tooltip title="click to upload">
                        <Button component="label" className="btn-upload-user">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : editData?.profileImage
                                  ? `data:image/jpg;base64,${editData?.profileImage}`
                                  : EmptyImage
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}
                <Typography
                  className="field-title"
                  sx={{ textAlign: "center", marginTop: "10px" }}
                >
                  រូបភាព
                </Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">នាម</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="នាម"
                  className="text-field"
                  {...getFieldProps("firstName")}
                  error={Boolean(touched.firstName && errors.firstName)}
                  helperText={touched.firstName && errors.firstName}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">គោត្តនាម</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="គោត្តនាម"
                  className="text-field"
                  {...getFieldProps("lastName")}
                  error={Boolean(touched.lastName && errors.lastName)}
                  helperText={touched.lastName && errors.lastName}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">
                    ឈ្មោះជាភាសាឡាតាំង
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="ឈ្មោះជាភាសាឡាតាំង"
                  className="text-field"
                  {...getFieldProps("latinName")}
                  error={Boolean(touched.latinName && errors.latinName)}
                  helperText={touched.latinName && errors.latinName}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <Stack justifyContent="right">
                  <Stack direction="row">
                    <Typography className="field-title">ភេទ</Typography>
                    <Typography sx={{ color: "red" }}>*</Typography>
                  </Stack>
                  <FormControl fullWidth size="small">
                    <Select {...getFieldProps("gender")}>
                      <MenuItem value={"ប្រុស"}>ប្រុស</MenuItem>
                      <MenuItem value={"ស្រី"}>ស្រី</MenuItem>
                    </Select>
                  </FormControl>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">
                    លេខអត្តសញ្ញាណប័ណ្ណ
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="លេខអត្តសញ្ញាណប័ណ្ណ"
                  className="text-field"
                  {...getFieldProps("nationalId")}
                  error={Boolean(touched.nationalId && errors.nationalId)}
                  helperText={touched.nationalId && errors.nationalId}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">សញ្ជាត្តិ</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <Autocomplete
                  disablePortal
                  className="text-field"
                  options={CountryName}
                  onChange={(e, value) =>
                    setFieldValue("nationality", value?.label)
                  }
                  renderInput={(params) => (
                    <TextField
                      fullWidth
                      {...params}
                      size="small"
                      placeholder="nationality"
                      error={Boolean(touched.nationality && errors.nationality)}
                      helperText={touched.nationality && errors.nationality}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">លេខទូរស័ព្ទ</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  placeholder="លេខទូរស័ព្ទ"
                  size="small"
                  className="text-field"
                  {...getFieldProps("tel")}
                  error={Boolean(touched.tel && errors.tel)}
                  helperText={touched.tel && errors.tel}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">ថ្ងៃកំណើត</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <LocalizationProvider
                  className="text-field"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    // value={dateOfBirth}
                    value={values?.dateOfBirth}
                    onChange={(newValue) => {
                      setFieldValue("dateOfBirth", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="text-field"
                        size="small"
                        {...params}
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="row">
                  <Typography className="field-title">អ៊ីម៉ែល</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="អ៊ីម៉ែល"
                  className="text-field"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="row">
                  <Typography className="field-title">ទីកន្លែងកំណើត</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="ទីកន្លែងកំណើត"
                  className="text-field"
                  {...getFieldProps("placeOfBirth")}
                  error={Boolean(touched.placeOfBirth && errors.placeOfBirth)}
                  helperText={touched.placeOfBirth && errors.placeOfBirth}
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <Stack direction="row">
                  <Typography className="field-title">
                    លំនៅដ្ឋានបច្ចុប្បន្ន
                  </Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  placeholder="លំនៅដ្ឋានបច្ចុប្បន្ន"
                  className="text-field"
                  {...getFieldProps("currentAddress")}
                  error={Boolean(
                    touched.currentAddress && errors.currentAddress
                  )}
                  helperText={touched.currentAddress && errors.currentAddress}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack direction="row">
                  <Typography className="field-title">ដំណែង</Typography>
                  <Typography sx={{ color: "red" }}>*</Typography>
                </Stack>
                <SelectPosition
                  selectedVal={positionSelectValue}
                  setSelectedVal={setPositionSelectValue}
                // className={}
                />
                {!!errors.position && touched.position && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.position}
                  </FormHelperText>
                )}
              </Grid>

              {/* <Grid item xs={12} md={12}>
                <Typography className="field-title">ឯកសារភ្ជាប់ ៖</Typography>
                <Grid container spacing={1} sx={{ marginTop: "5px" }}>
                  <ListReference
                    requireField={requireField}
                    referenceFileImageList={referenceFileImageList}
                    deleteReferenceFileImageList={deleteReferenceFileImageList}
                    setUpdateReferenceFileImage={setUpdateReferenceFileImage}
                  />
                  <Grid item xs={4} md={3} lg={3} xl={3}>
                    <Stack direction="row" width="100%" justifyContent="center">
                      <Button
                        component="label"
                        style={{
                          width: "90px",
                          height: "120px",
                          border: "1px solid #707070",
                        }}
                        onClick={handleAddReferenceFileImage}
                      >
                        <PiPlusThin
                          style={{
                            height: "50px",
                            width: "50px",
                            color: "#707070",
                          }}
                        />
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Grid> */}

              <Grid item xs={12} md={12}>
                <Stack direction="row">
                  <Typography className="field-title">ផ្សេងៗ</Typography>
                </Stack>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("remark")}
                />
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>

      <DialogActions>
        <Stack width="100%">
          {loading ? (
            <Button className="btn-create" fullWidth>
              កំពុងដំណើរការ...
            </Button>
          ) : (
            <Button className="btn-create" fullWidth onClick={handleSubmit}>
              កែប្រែ
            </Button>
          )}
        </Stack>
      </DialogActions>
    </Dialog>
  );
}
