import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Grid,
  DialogContent,
  TextField,
} from "@mui/material";

import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { UPDATE_POSITION } from "../../Schema/Positions";
// Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { useMutation } from "@apollo/client";

const UpdatePosition = ({ open, handleClose, editData, setRefetch }) => {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [updatePosition] = useMutation(UPDATE_POSITION, {
    onCompleted: ({ updatePosition }) => {
      setLoading(false);
      // console.log("updatePosition::", updatePosition);
      if (updatePosition?.status) {
        setAlert(true, "success", updatePosition?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        // console.log("updatePosition::", updatePosition);
        setAlert(true, "error", updatePosition?.message);
      }
    },
    onError: (error) => {
      // console.log("updatePosition::", updatePosition);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    title: Yup.string().required("ទាមទារ!"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      updatePosition({
        variables: {
          title: values?.title,
          id: editData?._id,
        },
      });
    },
  });
  //   console.log(editData);
  useEffect(() => {
    if (editData) {
      setFieldValue("title", editData?.title);
    }
  }, [editData]);
  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
  } = formik;
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">កែប្រែតួនាទី</Typography>
          </Stack>
          {/* close button */}
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      {/* Body of dialog */}
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">កែប្រែតួនាទី</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder=""
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    កែប្រែតួនាទី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
};

export default UpdatePosition;
