import React, { useContext, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import { string, ref } from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { RESET_USER_PASSWORD } from "../../Schema/User";

export default function ResetPassword({
  open,
  handleClose,
  setRefetch,
  editData,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [resetPassword] = useMutation(RESET_USER_PASSWORD, {
    onCompleted: ({ resetPassword }) => {
      setLoading(false);
      // console.log("resetPassword::", resetPassword);
      if (resetPassword?.status === true) {
        setAlert(true, "success", resetPassword?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        setAlert(true, "error", resetPassword?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    newPassword: Yup.string()
      .required("ទាមទារ!")
      .min(8, "សូមបញ្ចូលពាក្យសម្ងាត់ឲ្យបាន ៨ ខ្ទង់!"),
    confirmPassword: string()
      .required("ទាមទារ!")
      .oneOf([ref("newPassword")], "ពាក្យសម្ងាត់មិនត្រឹមត្រូវ!"),
  });

  const formik = useFormik({
    initialValues: {
      newPassword: "",
      confirmPassword: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      resetPassword({
        variables: {
          id: editData?._id,
          newPassword: values?.newPassword,
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    setFieldValue,
    values,
    resetForm,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              ផ្លាស់ប្តូរពាក្យសម្ងាត់
            </Typography>
          </Stack>
          <Box sx={{ flexGrow: 1 }} />
          <IconButton onClick={handleClose}>
            <BiMinusCircle className="close-icon" />
          </IconButton>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={1.5} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">
                  ពាក្យសម្ងាត់ថ្មី
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  type={show ? "text" : "password"}
                  placeholder="ពាក្យសម្ងាត់ថ្មី"
                  {...getFieldProps("newPassword")}
                  error={Boolean(touched.newPassword && errors.newPassword)}
                  helperText={touched.newPassword && errors.newPassword}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="viewpw-icon" />
                        ) : (
                          <VisibilityOffIcon className="viewpw-icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">
                  ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type={show ? "text" : "password"}
                  placeholder="ផ្ទៀងផ្ទាត់ពាក្យសម្ងាត់"
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    រក្សាទុក
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
