import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
} from "@mui/material";
import { Link, Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import moment from "moment";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import FooterPagination from "../Components/Include/FooterPagination";
import LoadingPage from "../Components/Include/LoadingPage";
import EmptyData from "../Components/Include/EmptyData";
import CreateIcon from "../Assets/create-icon.svg";
import { SelectPosition } from "../Components/Include/DynamicSelect";
import ShareholderAction from "../Components/Shareholder/ShareholderAction";
import CreateShareholder from "../Components/Shareholder/CreateShareholder";
import { GET_SHAREHOLDER_PAGINATION } from "../Schema/Shareholder";

export default function Shareholder() {
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [positionSelectValue, setPositionSelectValue] = useState({
    id: "",
    title: "",
  });

  // console.log("positionSelectValue?.id", positionSelectValue?.id);
  const { refetch } = useQuery(GET_SHAREHOLDER_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
      position: positionSelectValue?.id ? positionSelectValue?.id : "ទាំងអស់",
    },
    onCompleted: ({ getShareholdersPagination }) => {
      // console.log("getShareholdersPagination::", getShareholdersPagination);
      setTableData(getShareholdersPagination?.data);
      setPaginationData(getShareholdersPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  const handleToDetails = (data) => {
    navigate("/shareholder/shareholder-details", { state: { data } });
  };

  // ================================ Resize width Screen ==================================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">ម្ចាស់ភាគហ៊ុន</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
            <Typography className="header-text">
              ស្វែងរកម្ចាស់ភាគហ៊ុន
            </Typography>
            <TextField
              placeholder="ស្វែងរក"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2} xl={2}>
            <Typography className="header-text">តួនាទី</Typography>
            <SelectPosition
              selectedVal={positionSelectValue}
              setSelectedVal={setPositionSelectValue}
              className="search-field"
            />
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={8} xl={8}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                បង្កើត
              </Button>
              <CreateShareholder
                open={open}
                handleClose={handleClose}
                setRefetch={refetch}
              />
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table">
            <TableHead className="header-row">
              <TableRow className="header-row">
                <TableCell
                  className="header-title-start"
                  width="4%"
                  align="left"
                >
                  ល.រ
                </TableCell>
                <TableCell className="header-title" align="left" width="20%">
                  នាម និងគោត្តនាម
                </TableCell>
                <TableCell className="header-title" align="center" width="6%">
                  ភេទ
                </TableCell>
                <TableCell className="header-title" align="center" width="6%">
                  អាយុ
                </TableCell>
                <TableCell className="header-title" width="12%">
                  អត្តសញ្ញាណប័ណ្ណ
                </TableCell>
                <TableCell className="header-title" width="12%">
                  សញ្ជាតិ
                </TableCell>
                <TableCell className="header-title" width="12%">
                  តួនាទី
                </TableCell>
                <TableCell className="header-title" width="20%">
                  ផ្សេងៗ
                </TableCell>
                <TableCell className="header-title-end"></TableCell>
              </TableRow>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={10} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={10} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="body">
                    {tableData?.map((row, index) => {
                      // console.log("row::", row);
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell
                            className="body-cell-start"
                            onClick={() => handleToDetails(row)}
                          >
                            {index + paginationData?.slNo}
                          </TableCell>

                          <TableCell
                            className="body-cell"
                            onClick={() => handleToDetails(row)}
                          >
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                sx={{ width: 40, height: 40 }}
                                alt={row?.lastName}
                                // src={`data:image/jpg;base64,${row?.profileImage}`}
                                src={`data:image/jpg;base64,${row?.profileImage}`}
                              />
                              <Stack direction="column" justifyContent="center">
                                <Typography
                                  className="member-name"
                                  fontWeight="bold"
                                >
                                  {row?.firstName + " " + row?.lastName}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            align="center"
                            onClick={() => handleToDetails(row)}
                          >
                            {row?.gender}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            align="center"
                            onClick={() => handleToDetails(row)}
                          >
                            {moment().diff(row?.dateOfBirth, "years")}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleToDetails(row)}
                          >
                            {row?.nationalId}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleToDetails(row)}
                          >
                            {row?.nationality}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleToDetails(row)}
                          >
                            {row?.position}
                          </TableCell>
                          <TableCell
                            className="body-cell"
                            onClick={() => handleToDetails(row)}
                          >
                            {row?.remark}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <ShareholderAction
                              editData={row}
                              setRefetch={refetch}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* <ViewProgramme
          programmeId={programmeId}
          open={openView}
          handleClose={handleCloseView}
        /> */}

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
          setLoading={setLoading}
        />
      </Box>
    </div>
  );
}
