import { gql } from "@apollo/client";

export const CREATE_SHAREHOLDER = gql`
  mutation CreateShareholder($input: ShareholderInput!) {
    createShareholder(input: $input) {
      status
      message
    }
  }
`;

export const GET_SHAREHOLDER_PAGINATION = gql`
  query Data($keyword: String!, $limit: Int!, $page: Int!, $position: ID!) {
    getShareholdersPagination(
      keyword: $keyword
      limit: $limit
      page: $page
      position: $position
    ) {
      data {
        _id
        firstName
        lastName
        latinName
        dateOfBirth
        nationalId
        profileImage
        positionId
        position
        gender
        tel
        email
        placeOfBirth
        nationality
        currentAddress
        referenceFiles
        idCard
        remark
      }
      female
      male
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_SHAREHOLDER = gql`
  mutation UpdateShareholder($id: ID!, $input: ShareholderInput!) {
    updateShareholder(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_SHAREHOLDER = gql`
  mutation DeleteShareholder($id: ID!) {
    deleteShareholder(_id: $id) {
      status
      message
    }
  }
`;

export const GET_SELLING_SHARE_PAGINATION = gql`
  query GetSellingSharesPaginator(
    $page: Int!
    $limit: Int!
    $shareholderId: ID!
  ) {
    getSellingSharesPaginator(
      page: $page
      limit: $limit
      shareholderId: $shareholderId
    ) {
      data {
        _id
        title
        titleKh
        numbering
        date
        nationalId
        firstName
        lastName
        tel
        email
        share
        price
        priceUSD
        priceKHR
        note
        totalCashUSD {
          cash
          qty
          total
        }
        totalCashKHR {
          cash
          qty
          total
        }
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const GET_SELING_SHARE_DETAILS = gql`
  query GetSellingShareDetail($id: ID!) {
    getSellingShareDetail(_id: $id) {
      _id
      title
      titleKh
      numbering
      date
      nationalId
      firstName
      lastName
      tel
      email
      share
      price
      priceUSD
      priceKHR
      note
      totalCashUSD {
        cash
        qty
        total
      }
      totalCashKHR {
        cash
        qty
        total
      }
    }
  }
`;
