import { Stack, Pagination, Select, MenuItem } from "@mui/material";

export default function FooterPagination({
  totalPages,
  totalDocs,
  limit,
  page,
  setPage,
  handleLimit,
  Type,
  setLoading,
}) {
  const handlePageNum = (event, pageNum) => {
    setPage(parseInt(pageNum));
    if (Type === "student") {
      window.localStorage.setItem("pageStudent", parseInt(pageNum));
    } else if (Type === "parent") {
      window.localStorage.setItem("pageParent", parseInt(pageNum));
    }
  };
  return (
    <Stack
      direction="row"
      justifyContent="right"
      spacing={2}
      sx={{ marginTop: "20px" }}
    >
      <Stack direction="column" justifyContent="center">
        <Pagination
          hideNextButton={false}
          hidePrevButton={false}
          page={page}
          count={totalPages}
          color="primary"
          variant="outlined"
          onChange={
            (event, pageNum) => {
              setLoading(true)
              handlePageNum(event, pageNum)
            }
          }
        />
      </Stack>
      <Select size="small" value={limit} onChange={handleLimit}>
        <MenuItem value={6}>6/ទំព័រ</MenuItem>
        <MenuItem value={8}>8/ទំព័រ</MenuItem>
        <MenuItem value={10}>10/ទំព័រ</MenuItem>
        <MenuItem value={12}>12/ទំព័រ</MenuItem>
        <MenuItem value={totalDocs}>ទាំងអស់/ទំព័រ</MenuItem>
      </Select>
    </Stack>
  );
}
