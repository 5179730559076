import React, { useState } from "react";
import {
  Stack,
  IconButton,
  Box,
  TextField,
  Typography,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { MdOutlineAddCircleOutline, MdDeleteOutline } from "react-icons/md";

export default function ListTotalCashKHR({
  handleUpdatePriceKHR,
  handleUpdateQTYKHR,
  handleUpdateValueKHR,
  handleDeleteCashKHR,
  handleAddCashKHR,
  totalCashKHRList,
  requireField,
}) {
  const [touched, setTouched] = useState(false);
  const handleTouched = (e) => {
    setTouched(true);
    e.target.select();
  };

  return (
    <Stack direction="column" spacing={1}>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          backgroundColor: "#E6F1EE",
          borderRadius: "5px",
          padding: "0px 3px 0px 6px",
        }}
      >
        <Stack direction="column" width="31%">
          <Typography className="field-title">សាច់ប្រាក់</Typography>
        </Stack>
        <Stack direction="column" width="30%">
          <Typography className="field-title"> ចំនួន </Typography>
        </Stack>
        <Stack direction="column" width="31%">
          <Typography className="field-title"> សាច់ប្រាក់សរុប </Typography>
        </Stack>
        <Stack direction="column" width="8%">
          <Box>
            <IconButton onClick={handleAddCashKHR}>
              <MdOutlineAddCircleOutline color="#007654" />
            </IconButton>
          </Box>
        </Stack>
      </Stack>
      <Stack direction="column" spacing={1}>
        {totalCashKHRList?.map((row, index) => {
          return (
            <Stack direction="row" width="100%" spacing={1} key={index}>
              <Stack direction="column" width="42%">
                <FormControl className="text-field" fullWidth size="small">
                  <Select
                    value={row?.cash}
                    onChange={(e) =>
                      handleUpdatePriceKHR(e?.target.value, row?.key)
                    }
                    error={
                      (touched && row?.cash === undefined) ||
                      (touched && row?.cash === null) ||
                      (touched && row?.cash === "") ||
                      (touched && row?.cash <= 0) ||
                      (requireField && row?.cash === undefined) ||
                      (requireField && row?.cash === "") ||
                      (requireField && row?.cash <= 0) ||
                      (requireField && row?.cash === null)
                    }
                  >
                    <MenuItem value={100000}>100000</MenuItem>
                    <MenuItem value={50000}>50000</MenuItem>
                    <MenuItem value={20000}>20000</MenuItem>
                    <MenuItem value={10000}>10000</MenuItem>
                    <MenuItem value={5000}>5000</MenuItem>
                    <MenuItem value={2000}>2000</MenuItem>
                    <MenuItem value={1000}>1000</MenuItem>
                    <MenuItem value={500}>500</MenuItem>
                    <MenuItem value={200}>200</MenuItem>
                    <MenuItem value={100}>100</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
              <Stack direction="column">
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type="number"
                  inputProps={{ min: 0 }}
                  value={row?.qty}
                  onChange={(e) =>
                    handleUpdateQTYKHR(e?.target?.value, row?.key)
                  }
                  onFocus={handleTouched}
                  error={
                    (touched && row?.qty === undefined) ||
                    (touched && row?.qty === null) ||
                    (touched && row?.qty === "") ||
                    (touched && row?.qty === 0) ||
                    (requireField && row?.qty === undefined) ||
                    (requireField && row?.qty === "") ||
                    (requireField && row?.qty === 0) ||
                    (requireField && row?.qty < 0) ||
                    (requireField && row?.qty === null)
                  }
                />
              </Stack>
              <Stack direction="column">
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type="number"
                  inputProps={{ min: 0 }}
                  value={row?.cash * row?.qty}
                  onChange={(e) =>
                    handleUpdateValueKHR(e?.target?.value, row?.key)
                  }
                  // onFocus={handleTouched}
                  // error={
                  //   (touched && row?.value === undefined) ||
                  //   (touched && row?.value === null) ||
                  //   (touched && row?.value === "") ||
                  //   (touched && row?.value === 0) ||
                  //   (requireField && row?.value === undefined) ||
                  //   (requireField && row?.value === "") ||
                  //   (requireField && row?.value === 0) ||
                  //   (requireField && row?.value < 0) ||
                  //   (requireField && row?.value === null)
                  // }
                  disabled
                />
              </Stack>
              <Stack direction="column" width="8%">
                <Box>
                  <IconButton onClick={() => handleDeleteCashKHR(row.key)}>
                    <MdDeleteOutline color="#f44336" />
                  </IconButton>
                </Box>
              </Stack>
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
}
