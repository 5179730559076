import React, { useState } from "react";
import { Stack, IconButton, Tooltip, Box } from "@mui/material";
//Icons
import { MdDelete, MdModeEdit } from "react-icons/md";
//Srcs
import UpdateSellingShares from "./UpdateSellingShares";

export default function ShareholderAction({ editData, setRefetch }) {

    // Delete
    const [openDelete, setOpenDelete] = useState(false);
    const handleOpenDelete = (e) => {
        setOpenDelete(true);
    };
    const handleCloseDelete = () => {
        setOpenDelete(false);
    };
    // Update
    const [openEdit, setOpenEdit] = useState(false);
    const handleOpenEdit = (e) => {
        setOpenEdit(true);
    };
    const handleCloseEdit = () => {
        setOpenEdit(false);
    };

    return (
        <div>
            <Stack direction="row" justifyContent="right">
                <Tooltip title="កែប្រែ" placement="top">
                    <IconButton onClick={handleOpenEdit}>
                        <Box className="update-container">
                            <MdModeEdit className="update-icon" />
                        </Box>
                    </IconButton>
                </Tooltip>
                {/* <Tooltip title="លុប" placement="top">
          <IconButton onClick={handleOpenDelete}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip> */}
            </Stack>

            <UpdateSellingShares
                open={openEdit}
                handleClose={handleCloseEdit}
                editData={editData}
                setRefetch={setRefetch}
            />
        </div>
    );
}
