import React, { useEffect, useState, useRef } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
} from "@mui/material";
import { useQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
//Srcs
import "./shareholdervalue.scss";
import EmptyData from "../../Components/Include/EmptyData";
import { currencyFormatKh, currencyFormat } from "../../Function/DynamicFn";
import moment from "moment";
import { GET_SELLING_SHARE_PAGINATION } from "../../Schema/Shareholder";
import ShareholderForm from "./ShareholderForm";
import FooterPagination from "../Include/FooterPagination";
import SallingSharesAction from "./Share/SallingSharesAction"

export default function Shareholder({ editData }) {
  const [loading, setLoading] = useState(true);
  const [allData, setAllData] = useState();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(12);
  const [paginationData, setPaginationData] = useState();
  // const [team, setTeam] = useState("");

  const { refetch } = useQuery(GET_SELLING_SHARE_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      shareholderId: editData?._id,
    },
    onCompleted: ({ getSellingSharesPaginator }) => {
      // console.log("getSellingSharesPaginator", getSellingSharesPaginator);
      setTableData(getSellingSharesPaginator?.data);
      setPaginationData(getSellingSharesPaginator?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };

  return (
    <div className="page-container">
      <Box className="body-container">
        <TableContainer className="table-container">
          <Table className="table">
            <TableHead className="header-row">
              <TableRow className="header-row">
                <TableCell
                  className="header-title-start"
                  width="4%"
                  align="left"
                >
                  ល.រ
                </TableCell>
                <TableCell className="header-title" width="10%" align="center">
                  កាលបរិច្ឆេទ
                </TableCell>
                <TableCell className="header-title" width="10%" align="center">
                  ចំនួនហ៊ុន
                </TableCell>
                <TableCell className="header-title" width="10%" align="center">
                  តម្លៃហ៊ុន/៛
                </TableCell>
                <TableCell className="header-title" width="5%" align="center">
                  ទឹកប្រាក់/៛
                </TableCell>
                <TableCell className="header-title" width="10%" align="center">
                  ទឹកប្រាក់/$
                </TableCell>

                {/* <TableCell className="header-title-end" width="25%"></TableCell> */}
              </TableRow>
            </TableHead>
            {tableData?.length === 0 ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={8} className="body-cell">
                    <EmptyData />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody className="body">
                {tableData?.map((row, index) => {
                  return (
                    <TableRow
                      className="body-row"
                      style={{ height: "50px !important" }}
                      key={index}
                    >
                      <TableCell
                        className="body-cell-start"
                        onClick={() => {
                          handleOpen();
                          setAllData(row);
                        }}
                      >
                        {index + 1}
                      </TableCell>

                      <TableCell
                        className="body-cell"
                        align="center"
                        onClick={() => {
                          handleOpen();
                          setAllData(row);
                        }}
                      >
                        {moment(row?.date).format("DD-MM-YYYY")}
                      </TableCell>
                      <TableCell
                        className="body-cell"
                        align="center"
                        onClick={() => {
                          handleOpen();
                          setAllData(row);
                        }}
                      >
                        {row?.share}
                      </TableCell>
                      <TableCell
                        className="body-cell"
                        align="center"
                        onClick={() => {
                          handleOpen();
                          setAllData(row);
                        }}
                      >
                        {currencyFormatKh(row?.price)}
                      </TableCell>
                      <TableCell
                        className="body-cell"
                        align="center"
                        onClick={() => {
                          handleOpen();
                          setAllData(row);
                        }}
                      >
                        {row?.priceKHR ? currencyFormatKh(row?.priceKHR) : "0 ៛"}
                      </TableCell>
                      <TableCell
                        className="body-cell"
                        align="center"
                        onClick={() => {
                          handleOpen();
                          setAllData(row);
                        }}
                      >
                        {currencyFormat(row?.priceUSD)}
                      </TableCell>
                      <TableCell className="body-cell-end">
                        <SallingSharesAction editData={row} setRefetch={refetch} />
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            )}
          </Table>
        </TableContainer>
        <ShareholderForm
          open={open}
          handleClose={handleClose}
          editData={allData}
        />
        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
