import React, { useContext, useState, useMemo, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
import moment from "moment";

import {
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
  FormHelperText,
  Dialog,
  DialogTitle,
  DialogContent,
  FormControl,
  Select,
  MenuItem,
  InputAdornment,
} from "@mui/material";
import { useMutation } from "@apollo/client";
// Date picker
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../../Style/dialogstyle.scss";
import ListTotalCashKHR from "./ListTotalCashKHR";
import ListTotalCashUSD from "./ListTotalCashUSD";
import { SelectShareholder, SelectShare } from "../../Include/DynamicSelect";
import { AuthContext } from "../../../Context/AuthContext";
import { CREATE_SELLINGSHARES } from "../../../Schema/Shares";

export default function CreateSellingShares({
  open,
  handleClose,
  editData,
}) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);
  const [shareholderVal, setShareholderVal] = useState({ id: "", title: "" });
  const [shareVal, setShareVal] = useState({ id: "", title: "" });

  const [sellingShare] = useMutation(CREATE_SELLINGSHARES, {
    onCompleted: ({ sellingShare }) => {
      setLoading(false);
      // console.log("sellingShare::", sellingShare);
      if (sellingShare?.status) {
        setAlert(true, "success", sellingShare?.message);
        handleClose();
        resetForm();
        setShareholderVal({ id: "", title: "" });
        setShareVal({ id: "", title: "" });
        // setRefetch()
        setTotalCashKHRList([
          {
            cash: 0,
            qty: 0,
            total: 0,
            key: Date.now(),
          },
        ]);
        setTotalCashUSDList([
          {
            cash: 0,
            qty: 0,
            total: 0,
            key: Date.now(),
          },
        ]);

        // setRefetch();
      } else {
        setAlert(true, "error", sellingShare?.message);
      }
    },
    onError: (error) => {
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //================Total Cash KHR
  const [requireField, setRequireField] = useState(false);
  const [totalCashKHRList, setTotalCashKHRList] = useState([]);
  const [currentTotalCashKHR, setCurrentTotalCashKHR] = useState({
    cash: 0,
    qty: 0,
    total: 0,
    key: Date.now(),
  });

  const addCashKHR = () => {
    let newCashKHRList = currentTotalCashKHR;
    if (newCashKHRList !== 0) {
      const items = [...totalCashKHRList, newCashKHRList];
      setTotalCashKHRList([...items]);
      setCurrentTotalCashKHR({
        cash: 0,
        qty: 0,
        total: 0,
        key: "",
      });
    }
  };

  const handleAddCashKHR = () => {
    setCurrentTotalCashKHR({
      cash: 0,
      qty: 0,
      total: 0,
      key: Date.now(),
    });
  };

  useMemo(async () => {
    await addCashKHR();
  }, []);

  useEffect(() => {
    if (currentTotalCashKHR?.key !== "") {
      addCashKHR();
    }
  }, [currentTotalCashKHR]);

  const handleUpdatePriceKHR = (cash, key) => {
    let items = totalCashKHRList;
    items?.map((i) => {
      if (i.key === key) {
        i.cash = cash;
      }
      if (
        cash !== undefined ||
        cash !== null ||
        cash !== "" ||
        parseFloat(cash) > 0
      ) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setTotalCashKHRList([...items]);
  };

  const handleUpdateQTYKHR = (qty, key) => {
    let items = totalCashKHRList;
    items?.map((i) => {
      if (i.key === key) {
        i.qty = qty;
      }
      if (
        qty !== undefined ||
        qty !== null ||
        qty !== "" ||
        parseFloat(qty) > 0
      ) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setTotalCashKHRList([...items]);
  };
  const handleUpdateValueKHR = (total, key) => {
    let items = totalCashKHRList;
    items?.map((i) => {
      if (i.key === key) {
        i.total = total;
      }
      if (total !== undefined || total !== null || total !== "" || total > 0) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setTotalCashKHRList([...items]);
  };

  const handleDeleteCashKHR = (key) => {
    const filterCashKHR = totalCashKHRList?.filter((d) => d.key !== key);
    setTotalCashKHRList(filterCashKHR);
  };

  //================Total Cash USD
  const [totalCashUSDList, setTotalCashUSDList] = useState([]);
  const [currentTotalCashUSD, setcurrentTotalCashUSD] = useState({
    cash: 0,
    qty: 0,
    total: 0,
    key: Date.now(),
  });

  const addCashUSD = () => {
    let newCashUSDList = currentTotalCashUSD;
    if (newCashUSDList !== 0) {
      setTotalCashUSDList([...totalCashUSDList, newCashUSDList]);
      setcurrentTotalCashUSD({
        cash: 0,
        qty: 0,
        total: 0,
        key: "",
      });
    }
  };
  const handleAddCashUSD = () => {
    setcurrentTotalCashUSD({
      cash: 0,
      qty: 0,
      total: 0,
      key: Date.now(),
    });
  };

  useMemo(async () => {
    addCashUSD();
  }, []);

  useEffect(() => {
    if (currentTotalCashUSD?.key !== "") {
      addCashUSD();
    }
  }, [currentTotalCashUSD]);

  const handleUpdatePriceUSD = (cash, key) => {
    let items = totalCashUSDList;
    items?.map((i) => {
      if (i.key === key) {
        i.cash = cash;
      }
      if (
        cash !== undefined ||
        cash !== null ||
        cash !== "" ||
        parseFloat(cash) > 0
      ) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setTotalCashUSDList([...items]);
  };
  const handleUpdateQTYUSD = (qty, key) => {
    let items = totalCashUSDList;
    items?.map((i) => {
      if (i.key === key) {
        i.qty = qty;
      }
      if (
        qty !== undefined ||
        qty !== null ||
        qty !== "" ||
        parseFloat(qty) > 0
      ) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setTotalCashUSDList([...items]);
  };
  const handleUpdateValueUSD = (value, key) => {
    let items = totalCashUSDList;
    items?.map((i) => {
      if (i.key === key) {
        i.value = value;
      }
      if (value !== undefined || value !== null || value !== "" || value > 0) {
        setRequireField(false);
      } else {
        setRequireField(true);
      }
    });
    setTotalCashUSDList([...items]);
  };

  const handleDeleteCashUSD = (key) => {
    const filterCashUSD = totalCashUSDList?.filter((k) => k.key !== key);
    setTotalCashUSDList(filterCashUSD);
  };
  //fromik
  const CheckValidation = Yup.object().shape({
    numbering: Yup.string().required("ទាមទារ!"),
    date: Yup.string(),
    note: Yup.string().required("ទាមទារ!"),
    price: Yup.number().required("ទាមទារ!").min(1, "តម្លៃហ៊ុនត្រូវតែធំជាង០!"),
    priceKHR: Yup.number()
      .required("ទាមទារ!")
      .min(0, "ទឹកប្រាក់ជា(៛)ត្រូវតែធំជាងឬស្មើ០!"),
    priceUSD: Yup.number()
      .required("ទាមទារ!")
      .min(0, "ទឹកប្រាក់ជា($)ត្រូវតែធំជាងឬស្មើ០!"),
    share: Yup.number()
      .required("ទាមទារ!")
      .min(1, "ចំនួនភាគហ៊ុនត្រូវតែធំជាង០!"),
    shareId: Yup.string().required("ទាមទារ!"),
    shareholder: Yup.string().required("ទាមទារ!"),
  });

  const formik = useFormik({
    initialValues: {
      numbering: "",
      date: moment(),
      note: "",
      price: 0,
      priceKHR: 0,
      priceUSD: 0,
      share: 0,
      shareId: "",
      shareholder: editData?._id,
    },
    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      let createStatus = false;
      const BreakException = {};
      try {
        if (totalCashKHRList !== 0) {
          totalCashKHRList.forEach(function (eleKHR) {
            if (
              eleKHR?.cash !== 0 &&
              eleKHR?.cash > 0 &&
              eleKHR?.cash !== undefined &&
              eleKHR?.cash !== null &&
              eleKHR?.cash !== "" &&
              eleKHR?.qty !== undefined &&
              eleKHR?.qty !== null &&
              eleKHR?.qty !== 0 &&
              eleKHR?.qty > 0 &&
              eleKHR?.qty !== ""
            ) {
              createStatus = true;
              setRequireField(false);
            } else {
              createStatus = false;
              setRequireField(true);
              throw BreakException;
            }
          });
        } else {
          setLoading(false);
          setRequireField(true);
        }

        if (totalCashUSDList !== 0) {
          totalCashUSDList.forEach(function (eleUSD) {
            if (
              eleUSD?.cash !== 0 &&
              eleUSD?.cash > 0 &&
              eleUSD?.cash !== undefined &&
              eleUSD?.cash !== null &&
              eleUSD?.cash !== "" &&
              eleUSD?.qty !== undefined &&
              eleUSD?.qty !== null &&
              eleUSD?.qty !== 0 &&
              eleUSD?.qty > 0 &&
              eleUSD?.qty !== ""
            ) {
              createStatus = true;
              setRequireField(false);
            } else {
              createStatus = false;
              setRequireField(true);
              throw BreakException;
            }
          });
        }
      } catch (e) {
        if (e !== BreakException) throw e;
      }

      if (createStatus) {
        setLoading(true);
        let newTotalCashKHRList = [];
        totalCashKHRList?.map((element) => {
          const items = {
            cash: parseFloat(element?.cash),
            qty: parseFloat(element?.qty),
            total: parseFloat(element?.qty) * parseFloat(element?.cash),
          };
          newTotalCashKHRList.push(items);
        });

        let newTotalCashUSDList = [];
        totalCashUSDList?.map((eleUSD) => {
          const itemsUSD = {
            cash: parseFloat(eleUSD?.cash),
            qty: parseFloat(eleUSD?.qty),
            total: parseFloat(eleUSD?.qty) * parseFloat(eleUSD?.cash),
          };
          newTotalCashUSDList.push(itemsUSD);
        });
        // console.log("values", values, newTotalCashKHRList, newTotalCashUSDList);

        sellingShare({
          variables: {
            input: {
              ...values,
              totalCashKHR: newTotalCashKHRList,
              totalCashUSD: newTotalCashUSDList,
            },
          },
        });
      }
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    setFieldValue,
    values,
  } = formik;

  useEffect(() => {
    if (shareholderVal?.id) {
      setFieldValue("shareholder", shareholderVal?.id);
    }
  }, [shareholderVal?.id]);

  useEffect(() => {
    if (shareVal?.id) {
      setFieldValue("shareId", shareVal?.id);
    }
  }, [shareVal?.id]);

  return (
    // Header
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">បង្កើតភាគហ៊ុន</Typography>
          </Stack>
          {/* close button */}
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      {/* Body of dialog */}
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={6}>
                <Typography className="field-title">លេខរៀង</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="លេខរៀង"
                  {...getFieldProps("numbering")}
                  error={Boolean(touched.numbering && errors.numbering)}
                  helperText={touched.numbering && errors.numbering}
                />
              </Grid>
              <Grid item xs={6}></Grid>
              <Grid item xs={6}>
                <Typography className="field-title">កាលបរិច្ឆេទ</Typography>
                <LocalizationProvider
                  className="text-field"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    value={values?.date}
                    onChange={(newValue) => {
                      setFieldValue("date", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="text-field"
                        size="small"
                        {...params}
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">ចំនួនហ៊ុន</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  type="number"
                  onFocus={(e) => e.target.select()}
                  inputProps={{ min: 0 }}
                  {...getFieldProps("share")}
                  error={Boolean(touched.share && errors.share)}
                  helperText={touched.share && errors.share}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">ការលក់ហ៊ុនលើកទី</Typography>
                <SelectShare
                  selectedVal={shareVal}
                  setSelectedVal={setShareVal}
                  className="text-field"
                />
                {!!errors.shareId && touched.shareId && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.shareId}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">តម្លៃហ៊ុន</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  inputProps={{ min: 0 }}
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">ទឹកប្រាក់ជា (៛)</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="រៀល (៛)"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  inputProps={{ min: 0 }}
                  {...getFieldProps("priceKHR")}
                  error={Boolean(touched.priceKHR && errors.priceKHR)}
                  helperText={touched.priceKHR && errors.priceKHR}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">ទឹកប្រាក់ជា ($)</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ដុល្លារ​​ ($)"
                  onFocus={(e) => e.target.select()}
                  type="number"
                  inputProps={{ min: 0 }}
                  {...getFieldProps("priceUSD")}
                  error={Boolean(touched.priceUSD && errors.priceUSD)}
                  helperText={touched.priceUSD && errors.priceUSD}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">
                  សរុបសាច់ប្រាក់(៛)
                </Typography>
                <ListTotalCashKHR
                  totalCashKHRList={totalCashKHRList}
                  handleAddCashKHR={handleAddCashKHR}
                  handleDeleteCashKHR={handleDeleteCashKHR}
                  handleUpdateValueKHR={handleUpdateValueKHR}
                  handleUpdateQTYKHR={handleUpdateQTYKHR}
                  handleUpdatePriceKHR={handleUpdatePriceKHR}
                  requireField={requireField}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">
                  សរុបសាច់ប្រាក់($)
                </Typography>
                <ListTotalCashUSD
                  totalCashUSDList={totalCashUSDList}
                  handleAddCashUSD={handleAddCashUSD}
                  handleUpdateQTYUSD={handleUpdateQTYUSD}
                  handleDeleteCashUSD={handleDeleteCashUSD}
                  handleUpdateValueUSD={handleUpdateValueUSD}
                  handleUpdatePriceUSD={handleUpdatePriceUSD}
                  requireField={requireField}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">សម្គាល់៖</Typography>
                {/* <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ផ្សេងៗ"
                  {...getFieldProps("note")}
                /> */}
                <FormControl fullWidth size="small">
                  <Select
                    value={values?.note}
                    onChange={(e) => setFieldValue("note", e?.target.value)}
                    error={Boolean(touched.note && errors.note)}
                    onFocus={touched.note && errors.note}
                    helperText={touched.note && errors.note}
                    className="text-field"
                    style={{ fontFamily: "KantumruyPro" }}
                  >
                    <MenuItem
                      value={"ការបង់ប្រាក់ទៅបេឡាករផ្ទាល់"}
                      style={{ fontFamily: "KantumruyPro" }}
                    >
                      ការបង់ប្រាក់ទៅបេឡាករផ្ទាល់
                    </MenuItem>
                    <MenuItem
                      value={"ការបង់ប្រាក់តាមគណនីធនាគារ"}
                      style={{ fontFamily: "KantumruyPro" }}
                    >
                      ការបង់ប្រាក់តាមគណនីធនាគារ
                    </MenuItem>
                    <MenuItem
                      value={"ការបង់ប្រាក់តាមរយៈមូលប្បទានប័ត្រ"}
                      style={{ fontFamily: "KantumruyPro" }}
                    >
                      ការបង់ប្រាក់តាមរយៈមូលប្បទានប័ត្រ
                    </MenuItem>
                  </Select>
                </FormControl>
                {!!errors.note && touched.note && (
                  <FormHelperText error id="outlined-adornment-password">
                    {errors.note}
                  </FormHelperText>
                )}
              </Grid>
              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    បង្កើតភាគហ៊ុន
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
