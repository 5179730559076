import React from "react";
import { Grid, Stack, Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
// icon
//Srcs
import "./setting.scss";
import user from "../Assets/online-registration.png";
import shares from "../Assets/shares1-icon.png";
import positions from "../Assets/positions-icon.png";
import AdministrativeDivisionImage from "../Assets/administrative_divisions.jpg";
export default function Setting() {
  return (
    <div className="setting-page">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">ការកំណត់</Typography>
        </Stack>
      </Stack>
      <Box sx={{ mt: "30px" }}>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Link to="/setting/user" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={1.8}
                className="setting-page-container"
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  className="page-image"
                >
                  <img alt="Exam icon" className="image" src={user} />
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Stack direction="column">
                    <Typography className="page-title">
                      អ្នកប្រើប្រាស់
                    </Typography>
                    <Typography className="page-description">
                      សម្រាប់បង្កើតអ្នកប្រើប្រាស់
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Link to="/setting/positions" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={1.8}
                className="setting-page-container"
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  className="page-image"
                >
                  <img alt="positions-icon" className="image" src={positions} />
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Stack direction="column">
                    <Typography className="page-title">តួនាទី</Typography>
                    <Typography className="page-description">
                      សម្រាប់បង្កើតតួនាទី
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Link>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={3}>
            <Link to="/setting/shares" style={{ textDecoration: "none" }}>
              <Stack
                direction="row"
                spacing={1.8}
                className="setting-page-container"
              >
                <Stack
                  direction="column"
                  justifyContent="center"
                  className="page-image"
                >
                  <img alt="shares1-icon" className="image" src={shares} />
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Stack direction="column">
                    <Typography className="page-title">ភាគហ៊ុន</Typography>
                    <Typography className="page-description">
                      សម្រាប់បង្កើតភាគហ៊ុន
                    </Typography>
                  </Stack>
                </Stack>
              </Stack>
            </Link>
          </Grid>
        </Grid>
      </Box>
    </div>
  );
}
