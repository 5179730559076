import React, { useEffect, useState } from "react";
import { Grid, Typography, Stack } from "@mui/material";
import moment from "moment";
//Srcs
import "./personalinfo.scss";
import EmptyImage from "../../../Assets/empty-image.png";
import ImageSlider from "./ImageSilder";

const containerStyles = {
  width: "400px",
  height: "280px",
  // margin: "0 auto",
};

export default function PersonalInfo({ editData }) {
  // console.log("editData::", editData);

  // const [images, setImages] = useState([])
  // useEffect(() => {
  //   if (editData?.referenceFiles?.length !== 0) {
  //     let allImage = [];
  //     editData?.referenceFiles?.map((i, index) => {
  //       const allUrl = {
  //         // url: `data:image/jpg;base64, ${i}`,
  //         url: i,
  //         title: "Image" + index,
  //       };
  //       allImage?.push(allUrl);
  //     });
  //     setImages(allImage);
  //   }
  // }, [editData?.referenceFiles?.length]);

  // console.log("images::", images)

  // const images = [
  //   { url: "https://static.vecteezy.com/system/resources/thumbnails/025/181/412/small/picture-a-captivating-scene-of-a-tranquil-lake-at-sunset-ai-generative-photo.jpg", title: "beach" },
  //   { url: "https://png.pngtree.com/thumb_back/fh260/background/20230804/pngtree-beautiful-image-of-a-lake-in-countryside-in-a-summer-time-image_13000543.jpg", title: "boat" },
  //   { url: "https://png.pngtree.com/thumb_back/fh260/background/20230816/pngtree-a-boat-on-the-water-at-a-lake-during-sunrise-image_13022919.jpg", title: "forest" },
  // ];

  return (
    <div className="personal-info">
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={5} lg={3} xl={3}>
          <Stack direction="column" alignItems="center" mt={4}>
            <Stack direction="row" alignItems="center">
              <img
                // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRfu8S3ZuVz0Q9xwjG330bo1HG6NhmhhLDKzg8hP5bOpw&s"
                src={
                  editData?.profileImage
                    ? `data:image/jpg;base64,${editData?.profileImage}`
                    : EmptyImage
                }
                className="image-list"
              />
            </Stack>
            <Typography className="profile-name" mt={2}>
              {editData?.firstName + " " + editData?.lastName}
            </Typography>
            <Typography className="profile-name">
              {editData?.latinName ?? "--- : ---"}
            </Typography>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={4.5} xl={4.5}>
          <Stack direction="column" spacing={2} mt={4}>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                តួនាទី
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {editData?.position}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                ភេទ
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {editData?.gender}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                អត្តសញ្ញាណ
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {editData?.nationalId}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                សញ្ជាត្តិ
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {editData?.nationality}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                ថ្ងៃខែឆ្នាំកំណើត
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {moment(editData?.dateOfBirth).format("DD-MM-YYYY")}
              </Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                ទូរស័ព្ទលេខ
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">{editData?.tel}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                អ៊ីម៉ែល
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">{editData?.email}</Typography>
            </Stack>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                ទីកន្លែងកំណើត
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {editData?.placeOfBirth}
              </Typography>
            </Stack>
          </Stack>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4.5} xl={4.5}>
          <Stack direction="column" spacing={2} mt={4}>
            <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                ទីលំនៅបច្ចុប្បន្ន
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {editData?.currentAddress}
              </Typography>
            </Stack>
            {/* <Stack direction="row" spacing={2}>
              <Typography className="detial-font" width="110px">
                ឯកសារភ្ជាប់
              </Typography>
              <Typography className="detial-font">៖</Typography>
              <Typography className="detial-font">
                {
                  editData?.referenceFiles?.length !== 0 ? <div style={containerStyles}>
                    <ImageSlider slides={images} />
                  </div> : null
                }
              </Typography>
            </Stack> */}
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
