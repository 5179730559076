import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import moment from "moment";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import FooterPagination from "../Components/Include/FooterPagination";
import LoadingPage from "../Components/Include/LoadingPage";
import { GET_SHARES_PAGINATOR } from "../Schema/Shares";
import EmptyData from "../Components/Include/EmptyData";
import SharesAction from "../Components/Shares/SharesAction";
import CreateShares from "../Components/Shares/CreateShares";
import CreateIcon from "../Assets/create-icon.svg";
import { currencyFormat, currencyFormatKh } from "../Function/DynamicFn";

export default function Shares() {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [role, setRole] = useState("All");

  const { refetch } = useQuery(GET_SHARES_PAGINATOR, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
    },
    onCompleted: ({ getSharesPaginator }) => {
      setTableData(getSharesPaginator?.data);
      setPaginationData(getSharesPaginator?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [keyword]);

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  // console.log("tableData", tableData);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography className="page-title">ការកំណត់</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">ភាគហ៊ុន</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">ស្វែងរក</Typography>
            <TextField
              placeholder="ស្វែងរកភាគហ៊ុន"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                បង្កើត
              </Button>
            </Stack>
            <CreateShares
              open={open}
              handleClose={handleClose}
              setRefetch={refetch}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start" width="5%">
                ល.រ
              </TableCell>
              <TableCell className="header-title" width="12%" align="left">
                ចំណងជើងខ្មែរ
              </TableCell>
              <TableCell className="header-title" width="12%" align="left">
                ចំណងជើងអង់គ្លេស
              </TableCell>
              <TableCell className="header-title" width="10%" align="center">
                ថ្ងៃចាប់ផ្ដើម
              </TableCell>
              <TableCell className="header-title" width="10%" align="center">
                ថ្ងៃបញ្ចប់
              </TableCell>
              <TableCell className="header-title" width="10%" align="center">
                ចំនួនភាគហ៊ុន
              </TableCell>
              <TableCell className="header-title" width="10%" align="center">
                តម្លៃ
              </TableCell>
              <TableCell className="header-title-end" width="18%"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={8} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={8} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="body">
                    {tableData?.map((row, index) => {
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + paginationData?.slNo}
                          </TableCell>

                          <TableCell className="body-cell" align="left">
                            <Stack direction="column" justifyContent="center">
                              <Typography
                                className="member-name"
                                // fontWeight="bold"
                              >
                                {row?.titleKh}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell" align="left">
                            <Stack direction="column" justifyContent="center">
                              <Typography
                                className="member-name"
                                // fontWeight="bold"
                              >
                                {row?.title}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell" align="center">
                            {moment(row?.startDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell" align="center">
                            {moment(row?.endDate).format("DD-MM-YYYY")}
                          </TableCell>
                          <TableCell className="body-cell" align="center">
                            {row?.totalShare}
                          </TableCell>

                          <TableCell className="body-cell" align="center">
                            {currencyFormat(row?.price)}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <SharesAction editData={row} setRefetch={refetch} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
