import { gql } from "@apollo/client";

export const SELECT_INVITE_FOR_SELECT = gql`
  query GetInvitesForSelect {
    getInvitesForSelect {
      _id
      name
    }
  }
`;

export const SELECT_ALL_NOTIFY = gql`
  query GetAllNotifyForSelect {
    getAllNotifyForSelect {
      _id
      name
      profile
    }
  }
`;

export const INVITE_AS_FOR_SELECT = gql`
  query Query {
    getInviteAsForSelect
  }
`;
