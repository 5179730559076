import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "./layout.scss";
import { styled } from "@mui/material/styles";
import {
  Box,
  Backdrop,
  Drawer,
  Stack,
  Typography,
  Button,
  AppBar,
} from "@mui/material";
//icons
import CircularProgress from "@mui/material/CircularProgress";
import ReplayIcon from "@mui/icons-material/Replay";
import { Outlet, useLocation } from "react-router-dom";
import MenuNavbar from "../Components/Menu/MenuNavbar";
import TopNavbar from "../Components/Menu/TopNavbar";
import logo from "../Assets/logoLogin.svg";
import { GET_USER_LOGIN } from "../Schema/User";
import { useQuery } from "@apollo/client";
import { AuthContext } from "../Context/AuthContext";

const drawerWidth = 270;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

export default function Layout() {
  const navigate = useNavigate();

  const { dispatch, setRole } = useContext(AuthContext);

  const [open, setOpen] = useState(true);
  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  const [openMobile] = useState(false);

  // ===================== Resize width =========================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  //================= get user login ==============================
  const { data: dataUserLogin } = useQuery(GET_USER_LOGIN, {
    // pollInterval: 1000,
    onCompleted: ({ getStuInfoUserLogin }) => {
      setWidth(window.innerWidth);
      if (
        getStuInfoUserLogin?.data &&
        getStuInfoUserLogin?.data?.role !== null
      ) {
        setRole(getStuInfoUserLogin?.data?.role);
        window.localStorage.setItem(
          "role",
          JSON.stringify(getStuInfoUserLogin?.data?.role)
        );
      }
    },
    onError: (error) => {
      setWidth(window.innerWidth);
      let result = error.message.includes("Authorized");
      if (result) {
        window.localStorage.removeItem("userLogin");
        window.localStorage.removeItem("userStatus");
        window.localStorage.removeItem("token");
        navigate("/login");
        dispatch({
          type: "LOGGED_IN_USER",
          payload: null,
        });
        window.location.reload(false);
      }
    },
  });

  // ========================================= Sleep Screen ===================================
  const [openBackdrop, setOpenBackdrop] = React.useState(false);
  const [iconReload, setIconReload] = React.useState(false);

  const handleCloseBackdrop = () => {
    setIconReload(true);
    setTimeout(() => {
      setOpenBackdrop(false);
      setIconReload(false);
    }, 2000);
    // window.location.reload(location.pathname);
  };

  // ===============================================================================================

  if (openBackdrop) {
    return (
      <Backdrop
        className="backdrop-style"
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        transitionDuration={{ enter: 500, exit: 1000 }}
        open={openBackdrop}
      >
        <Stack
          direction="column"
          justifyContent="center"
          sx={{ height: "100vh" }}
        >
          <Box
            display="flex"
            flexDirection="column"
            justifyContent="center"
            height="90%"
          >
            <Box display="flex" justifyContent="center" width="100%">
              <img src={logo} alt="logo" width="45%" />
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Typography className="system-title">SEK MEAS SYSTEM</Typography>
            </Box>
            <Box display="flex" justifyContent="center" width="100%">
              <Button
                variant="contained"
                endIcon={
                  iconReload ? (
                    <CircularProgress sx={{ color: "white" }} size="25px" />
                  ) : (
                    <ReplayIcon />
                  )
                }
                onClick={handleCloseBackdrop}
              >
                Back to Page
              </Button>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1 }}></Box>
          <Box
            display="flex"
            justifyContent="center"
            width="100%"
            sx={{ mb: "10px" }}
          >
            <Typography className="power-title">
              Power By: GO GLOBAL TECH
            </Typography>
          </Box>
        </Stack>
      </Backdrop>
    );
  }

  return (
    <div>
      {width > 1400 ? (
        <Box sx={{ display: "flex" }}>
          <AppBar sx={{ boxShadow: "none" }} position="fixed" open={open}>
            <TopNavbar handleDrawerOpen={handleDrawerOpen} open={open} />
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={open}
          >
            <MenuNavbar
              open={open}
              handleDrawerOpen={handleDrawerOpen}
              handleDrawerClose={handleDrawerClose}
            />
          </Drawer>
          <Main open={open} sx={{ marginTop: "60px" }}>
            <Outlet />
          </Main>
        </Box>
      ) : (
        <Box sx={{ display: "flex" }}>
          <AppBar sx={{ boxShadow: "none" }} position="fixed" open={open}>
            <TopNavbar handleDrawerOpen={handleDrawerOpen} open={open} />
          </AppBar>
          <Drawer
            sx={{
              width: drawerWidth,
              flexShrink: 0,
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
            variant="persistent"
            anchor="left"
            open={openMobile}
          ></Drawer>
          <Main open={openMobile} sx={{ marginTop: "60px" }}>
            <Outlet />
          </Main>
        </Box>
      )}
    </div>
  );
}
