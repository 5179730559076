import React, { useContext, useEffect, useState } from "react";
import "./App.css";
import axios from "axios";
import Router from "./routes";
import { BrowserRouter as Routers } from "react-router-dom";
import { createTheme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { ThemeProvider } from "@mui/material/styles";
import AlertMessage from "./Components/AlertMessage/AlertMessage";
import { setContext } from "@apollo/client/link/context";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { AuthContext } from "./Context/AuthContext";
import { QueryClient, QueryClientProvider } from "react-query";

function App() {
  const { state } = useContext(AuthContext);
  const { user } = state;

  // ====================== set token =======================
  const [token, setToken] = React.useState(
    JSON.parse(window.localStorage.getItem("token"))
  );

  React.useEffect(() => {
    let userToken = JSON.parse(window.localStorage.getItem("token"));
    if (userToken) {
      setToken(userToken);
      return;
    }

    if (user?.token) {
      setToken(user?.token);
      window.localStorage.setItem("token", JSON.stringify(user?.token));
    } else {
      setToken("");
    }
  }, [user]);

  //================ CREATE THEME ====================
  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          // mode: "light",
          background: {
            default: "#EBEDEF",
          },
        },
        typography: {
          fontFamily: ["Century Gothic", "Siemreap"].join(","),
        },
      }),
    []
  );

  //================= Connect Apollo Client ======================

  const httpLink = createHttpLink({
    // uri: "http://192.168.2.30:4010/graphql",
    uri: process.env.REACT_APP_END_POINT,
  });

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        authorization: token,
      },
    };
  });

  const client = new ApolloClient({
    link: authLink.concat(httpLink),
    // link: ApolloLink.from([logoutLink, authLink, httpLink]),
    cache: new InMemoryCache(),
  });

  const queryClient = new QueryClient();

  return (
    <ApolloProvider client={client}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <Routers>
            <Router />
          </Routers>
          <CssBaseline />
        </ThemeProvider>
        <AlertMessage />
      </QueryClientProvider>
    </ApolloProvider>
  );
}
export default App;
