import React, { useState, useContext } from "react";
import "./topnavbar.scss";
import {
  Avatar,
  IconButton,
  Stack,
  Toolbar,
  Box,
  Typography,
  Drawer,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
//Icons
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { IoMdMenu } from "react-icons/io";
//Srcs
import MenuNavbarMobile from "./MenuNavbarMobile";
import { AuthContext } from "../../Context/AuthContext";
import LogoutMenu from "./LogoutMenu";

export default function TopNavbar({ open, handleDrawerOpen }) {
  let userLogin = JSON.parse(window.localStorage.getItem("userLogin"));
  // console.log("userLogin::", userLogin);

  // ==================================== check screen ================================
  const drawerWidth = 280;
  const [state, setState] = useState({ left: false });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  // ================== Function menu logout ===================
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ================== Function open logout ===================
  const [oepnLogout, setOpenLogout] = useState(false);
  const handleOpenLogout = () => {
    setOpenLogout(true);
    handleClose();
  };
  const handleCloseLogout = () => {
    setOpenLogout(false);
  };

  return (
    <Toolbar className="top-bar">
      <Stack
        direction="row"
        spacing={1}
        sx={{ width: "100%", height: "60px" }}
        justifyContent="space-between"
      >
        <Stack direction="column" justifyContent="center">
          <IconButton
            onClick={toggleDrawer("left", true)}
            edge="start"
            sx={{
              display: open ? "flex" : "none",
              bgcolor: "#2E3192 !important",
            }}
          >
            <IoMdMenu style={{ color: "#fff" }} />
          </IconButton>

          <Drawer
            anchor={"left"}
            open={state["left"]}
            onClose={toggleDrawer("left", false)}
          >
            <Box
              sx={{ width: drawerWidth }}
              role="presentation"
              onKeyDown={toggleDrawer("left", false)}
            >
              <MenuNavbarMobile toggleDrawer={toggleDrawer("left", false)} />
            </Box>
          </Drawer>
        </Stack>

        <Stack direction="row" spacing={1.4} justifyContent="right">
          <Stack direction="column" justifyContent="center">
            <Button className="btn-menu" onClick={handleClick}>
              <Stack direction="row" spacing={2}>
                <Stack direction="column" justifyContent="center">
                  <Avatar
                    src={userLogin?.profileImage}
                    alt="User"
                    className="avatar-user"
                  />
                </Stack>
                <Stack direction="column" justifyContent="center">
                  <Typography className="username-text">
                    {userLogin ? userLogin?.userName : "NO User"}
                  </Typography>
                </Stack>
                <Stack direction="column" justifyContent="center">
                  {openMenu ? (
                    <ArrowDropUpIcon className="icon-menu" />
                  ) : (
                    <ArrowDropDownIcon className="icon-menu" />
                  )}
                </Stack>
              </Stack>
            </Button>

            <Menu
              anchorEl={anchorEl}
              id="account-menu"
              open={openMenu}
              onClose={handleClose}
              onClick={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
              <MenuItem
                onClick={handleOpenLogout}
                style={{ fontFamily: "Kantumruy Pro" }}
              >
                ចាកចេញ
              </MenuItem>
            </Menu>

            <LogoutMenu open={oepnLogout} handleClose={handleCloseLogout} />
          </Stack>
        </Stack>
      </Stack>
    </Toolbar>
  );
}
