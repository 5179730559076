import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Box,
  Button,
  TableContainer,
  Table,
  TableRow,
  TableCell,
  TableBody,
  TableHead,
  TextField,
  Grid,
  InputAdornment,
  Avatar,
  FormControl,
  Select,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useQuery } from "@apollo/client";
//Icons
import SearchIcon from "@mui/icons-material/Search";
//Srcs
import "../Style/pagestyle.scss";
import FooterPagination from "../Components/Include/FooterPagination";
import LoadingPage from "../Components/Include/LoadingPage";
import { GET_USER_WITH_PAGINATION } from "../Schema/User";
import EmptyData from "../Components/Include/EmptyData";
import UserAction from "../Components/User/UserAction";
import CreateUser from "../Components/User/CreateUser";
import CreateIcon from "../Assets/create-icon.svg";

export default function User() {
  const [loading, setLoading] = useState(true);

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [tableData, setTableData] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [paginationData, setPaginationData] = useState();
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [role, setRole] = useState("All");

  const { refetch } = useQuery(GET_USER_WITH_PAGINATION, {
    variables: {
      page: page,
      limit: limit,
      keyword: keyword,
    },
    onCompleted: ({ getUsersPagination }) => {
      setTableData(getUsersPagination?.data);
      setPaginationData(getUsersPagination?.paginator);
      setLoading(false);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  useEffect(() => {
    refetch();
  }, [keyword]);

  const handleLimit = (e) => {
    setLimit(e.target.value);
    setPage(1);
  };
  // console.log("tableData", tableData);

  // ======================= Resize width Screen ======================
  const [width, setWidth] = useState(window.innerWidth);
  const updateDimensions = () => {
    setWidth(window.innerWidth - 50);
  };
  useEffect(() => {
    window.addEventListener("resize", updateDimensions);
    return () => window.removeEventListener("resize", updateDimensions);
  }, []);

  return (
    <div className="page-container">
      <Stack direction="row" spacing={2} className="page-header">
        <Stack direction="column" justifyContent="center">
          <Box className="slash" />
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Link className="page-link-title" to="/setting">
            <Typography className="page-title">ការកំណត់</Typography>
          </Link>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="slash-title">/</Typography>
        </Stack>
        <Stack direction="column" justifyContent="center">
          <Typography className="page-title">អ្នកប្រើប្រាស់</Typography>
        </Stack>
      </Stack>

      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">ស្វែងរក</Typography>
            <TextField
              placeholder="ស្វែងរកឈ្មោះអ្នកប្រើប្រាស់"
              size="small"
              className="search-field"
              onChange={(e) => setKeyword(e.target.value)}
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    sx={{ mr: 1, color: "black" }}
                  >
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {/* <Grid item xs={6} sm={4} md={4} lg={3} xl={2.4}>
            <Typography className="header-text">ជ្រើសរើសតួនាទី</Typography>
            <FormControl className="search-field" fullWidth size="small">
              <Select value={role} onChange={(e) => setRole(e.target.value)}>
                <MenuItem value="All">ទាំងអស់</MenuItem>
                <MenuItem value="ប្រធានក្រុមប្រឹក្សា">
                  ប្រធានក្រុមប្រឹក្សា
                </MenuItem>
                <MenuItem value="គណៈអភិបាលខេត្ត">គណៈអភិបាលខេត្ត</MenuItem>
                <MenuItem value="សមាជិកក្រុមប្រឹក្សា">
                  សមាជិកក្រុមប្រឹក្សា
                </MenuItem>
                <MenuItem value="អ្នកគ្រប់គ្រងកម្មវិធី">
                  អ្នកគ្រប់គ្រងកម្មវិធី
                </MenuItem>
                <MenuItem value="អភិបាលរងខេត្ត">អភិបាលរងខេត្ត</MenuItem>
                <MenuItem value="នាយករដ្ឋបាល">នាយករដ្ឋបាល</MenuItem>
                <MenuItem value="ជំនួយការ">ជំនួយការ</MenuItem>
              </Select>
            </FormControl>
          </Grid> */}

          <Grid item xs={6} sm={6} md={8} lg={9} xl={9.6}>
            <Stack direction="row" justifyContent="right">
              <Button
                onClick={handleOpen}
                className="btn-create"
                startIcon={
                  <img src={CreateIcon} alt="CreateIcon" className="icon-add" />
                }
              >
                បង្កើត
              </Button>
            </Stack>
            <CreateUser
              open={open}
              handleClose={handleClose}
              setRefetch={refetch}
            />
          </Grid>
        </Grid>
      </Box>

      <Box className="body-container">
        <TableContainer sx={{ maxWidth: `${width}px` }}>
          <Table className="table" aria-label="simple table">
            <TableHead className="header-row ">
              <TableCell className="header-title-start" width="2%">
                ល.រ
              </TableCell>
              <TableCell className="header-title" width="5%">
                ឈ្មោះអ្នកប្រើប្រាស់
              </TableCell>
              <TableCell className="header-title" width="10%">
                អ៊ីម៉ែល
              </TableCell>
              <TableCell className="header-title-end" width="18%"></TableCell>
            </TableHead>
            {loading ? (
              <TableBody className="body">
                <TableRow className="body-row">
                  <TableCell colSpan={6} className="body-cell">
                    <LoadingPage />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <>
                {tableData?.length === 0 ? (
                  <TableBody className="body">
                    <TableRow className="body-row">
                      <TableCell colSpan={6} className="body-cell">
                        <EmptyData />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                ) : (
                  <TableBody className="body">
                    {tableData?.map((row, index) => {
                      console.log("row::", row);
                      return (
                        <TableRow className="body-row" key={index}>
                          <TableCell className="body-cell-start">
                            {index + paginationData?.slNo}
                          </TableCell>

                          <TableCell className="body-cell">
                            <Stack direction="row" spacing={1}>
                              <Avatar
                                sx={{ width: 40, height: 40 }}
                                alt={row?.lastName}
                                src={`data:image/jpg;base64,${row?.profileImage}`}
                              />
                              <Stack direction="column" justifyContent="center">
                                <Typography
                                  className="member-name"
                                  fontWeight="bold"
                                >
                                  {row?.userName}
                                </Typography>
                              </Stack>
                            </Stack>
                          </TableCell>
                          <TableCell className="body-cell">
                            {row?.email}
                          </TableCell>
                          <TableCell className="body-cell-end" align="right">
                            <UserAction editData={row} setRefetch={refetch} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                )}
              </>
            )}
          </Table>
        </TableContainer>

        {/* ==================== Pagination  ============================= */}
        <FooterPagination
          totalPages={paginationData?.totalPages}
          totalDocs={paginationData?.totalDocs}
          limit={limit}
          page={page}
          setPage={setPage}
          handleLimit={handleLimit}
        />
      </Box>
    </div>
  );
}
