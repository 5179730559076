import React, { useState, useContext } from "react";
import {
  Stack,
  Typography,
  IconButton,
  Button,
  Grid,
  DialogContent,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
// Icons
import { BiMinusCircle } from "react-icons/bi";
//Srcs
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { useMutation } from "@apollo/client";
import { DELETE_POSITIONS } from "../../Schema/Positions";

const DeletePosition = ({ open, handleClose, editData, setRefetch }) => {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);

  // console.log("editData::",editData)

  const [deletePosition] = useMutation(DELETE_POSITIONS, {
    onCompleted: ({ deletePosition }) => {
      // console.log("deletePosition::", deletePosition);
      setLoading(false);
      if (deletePosition?.status === true) {
        setAlert(true, "success", deletePosition?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", deletePosition?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = () => {
    setLoading(true);
    deletePosition({
      variables: {
        id: editData?._id,
      },
    });
  };
  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">លុបតួនាទី</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="delete-descript">
              តើអ្នកពិតជាចង់លុបទិន្នន័យនេះមែនទេ?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-delete" fullWidth>
                កំពុងដំណើរការ...
              </Button>
            ) : (
              <Button className="btn-delete" fullWidth onClick={handleDelete}>
                លុប
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default DeletePosition;
