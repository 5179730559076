import React, { useContext, useState } from "react";

import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { DELETE_SHAREHOLDER } from "../../Schema/Shareholder";

export default function DeleteShareholder({
  open,
  handleClose,
  editData,
  setRefetch,
}) {
  const [loading, setLoading] = useState(false);
  const { setAlert } = useContext(AuthContext);

  // console.log("editData::",editData)

  const [deleteShareholder] = useMutation(DELETE_SHAREHOLDER, {
    onCompleted: ({ deleteShareholder }) => {
      // console.log("deleteShareholder::", deleteShareholder);
      setLoading(false);
      if (deleteShareholder?.status === true) {
        setAlert(true, "success", deleteShareholder?.message);
        handleClose();
        setRefetch();
      } else {
        setAlert(true, "error", deleteShareholder?.message);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });

  const handleDelete = () => {
    setLoading(true);
    deleteShareholder({
      variables: {
        id: editData?._id,
      },
    });
  };

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="xs">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">លុបម្ចាស់ភាគហ៊ុន</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography className="delete-descript">
              តើអ្នកពិតជាចង់លុបទិន្នន័យនេះមែនទេ?
            </Typography>
          </Grid>
          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-delete" fullWidth>
                កំពុងដំណើរការ...
              </Button>
            ) : (
              <Button className="btn-delete" fullWidth onClick={handleDelete}>
                លុប
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
}
