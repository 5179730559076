import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
  Avatar,
} from "@mui/material";
import { useMutation } from "@apollo/client";
import imageCompression from "browser-image-compression";
//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import EmptyImage from "../../Assets/empty-image.png";
import { AuthContext } from "../../Context/AuthContext";
// import { CREATE_USER } from "../../Schema/User";
import CropImageFile from "../CropImage/CropImageFile";
import { CREATE_USER } from "../../Schema/User";

export default function CreateUser({ open, handleClose, setRefetch }) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  // ========================= upload Image =========================
  const [profileHook, setProfileHook] = useState("");
  const [imageFile, setImageFile] = useState(null);
  const [photoURL, setPhotoURL] = useState(null);
  const [openCrop, setOpenCrop] = useState(false);

  const handleUploadImage = async (e) => {
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    const compressedFile = await imageCompression(imageFile, options);
    var newFile = new File([compressedFile], `${imageFile.name}.png`, {
      type: "image/png",
    });

    // console.log("newFile::", newFile);

    if (newFile) {
      setImageFile(newFile);
      setPhotoURL(URL.createObjectURL(newFile));
      setOpenCrop(true);
    }
  };

  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [createUser] = useMutation(CREATE_USER, {
    onCompleted: ({ createUser }) => {
      setLoading(false);
      // console.log("createUser::", createUser);
      if (createUser?.status) {
        setAlert(true, "success", createUser?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        // console.log("createUser::", createUser);
        setAlert(true, "error", createUser?.message);
      }
    },
    onError: (error) => {
      // console.log("createUser::", createUser);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  // const [mutateFunction] = useMutation(INCREMENT_COUNTER);

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  // min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
  //fromik
  const CheckValidation = Yup.object().shape({
    userName: Yup.string().required("require!"),
    email: Yup.string().email().required("require!"),
    password: Yup.string().required("Required").matches(passwordRules, {
      message: "Please enter a stronger password!",
    }),
    confirmPassword: Yup.string()
      .required("Please retype your password!")
      .oneOf([Yup.ref("password")], "Your passwords do not match!"),
  });

  const formik = useFormik({
    initialValues: {
      userName: "",
      email: "",
      password: "",
      confirmPassword: "",
    },

    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      createUser({
        variables: {
          profileImage: profileHook,
          userName: values?.userName,
          email: values?.email,
          password: values?.password,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, resetForm } = formik;

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">
              បង្កើតអ្នកប្រើប្រាស់
            </Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                {!openCrop ? (
                  <Box>
                    <Stack direction="row" justifyContent="center">
                      <Tooltip title="click to upload">
                        <Button component="label" className="btn-upload-user">
                          <TextField
                            type="file"
                            id="image"
                            sx={{ display: "none" }}
                            onChange={handleUploadImage}
                          />
                          <Avatar
                            className="avater-image"
                            src={
                              imageFile
                                ? URL.createObjectURL(imageFile)
                                : EmptyImage
                            }
                          />
                        </Button>
                      </Tooltip>
                    </Stack>
                  </Box>
                ) : (
                  <CropImageFile
                    openCrop={openCrop}
                    setOpenCrop={setOpenCrop}
                    photoURL={photoURL}
                    setPhotoURL={setPhotoURL}
                    setImageFile={setImageFile}
                    setProfileHook={setProfileHook}
                  />
                )}
                <Typography
                  className="field-title"
                  sx={{ textAlign: "center", marginTop: "10px" }}
                >
                  រូបភាព
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Typography className="field-title">
                  ឈ្មោះអ្នកប្រើប្រាស់
                </Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="ឈ្មោះអ្នកប្រើប្រាស់"
                  {...getFieldProps("userName")}
                  error={Boolean(touched.userName && errors.userName)}
                  helperText={touched.userName && errors.userName}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">អ៊ីម៉ែល</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="អ៊ីម៉ែល"
                  {...getFieldProps("email")}
                  error={Boolean(touched.email && errors.email)}
                  helperText={touched.email && errors.email}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">ពាក្យសម្ងាត់</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="ពាក្យសម្ងាត់"
                  type={show ? "text" : "password"}
                  {...getFieldProps("password")}
                  error={Boolean(touched.password && errors.password)}
                  helperText={touched.password && errors.password}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="start" onClick={handleClick}>
                        {show ? (
                          <VisibilityIcon className="viewpw-icon" />
                        ) : (
                          <VisibilityOffIcon className="viewpw-icon" />
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">
                  បញ្ជាក់ពាក្យសម្ងាត់
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="បញ្ជាក់ពាក្យសម្ងាត់"
                  type={show ? "text" : "password"}
                  {...getFieldProps("confirmPassword")}
                  error={Boolean(
                    touched.confirmPassword && errors.confirmPassword
                  )}
                  helperText={touched.confirmPassword && errors.confirmPassword}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    បង្កើត
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
