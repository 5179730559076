import { gql } from "@apollo/client";

export const CREATE_SHARES = gql`
  mutation CreateShare($input: ShareInput!) {
    createShare(input: $input) {
      message
      status
    }
  }
`;

export const GET_SHARES_PAGINATOR = gql`
  query Data($page: Int!, $limit: Int!, $keyword: String!) {
    getSharesPaginator(page: $page, limit: $limit, keyword: $keyword) {
      data {
        _id
        title
        startDate
        endDate
        totalShare
        price
        totalAmount
        status
        titleKh
        remark
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const UPDATE_SHARE = gql`
  mutation UpdateShare($id: ID!, $input: ShareInput) {
    updateShare(_id: $id, input: $input) {
      status
      message
    }
  }
`;

export const DELETE_SHARE = gql`
  mutation DeleteShare($id: ID!) {
    deleteShare(_id: $id) {
      status
      message
    }
  }
`;

export const CREATE_SELLINGSHARES = gql`
  mutation SellingShare($input: SellingShareInput!) {
    sellingShare(input: $input) {
      status
      message
    }
  }
`;

export const UPDATE_SELLINGSHARES = gql`
mutation UpdateSellingShare($input: SellingShareInputUpdate!, $id: ID!) {
  updateSellingShare(input: $input, _id: $id) {
    status
    message
  }
}
`