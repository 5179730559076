import React, { useContext, useState, useEffect } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  Box,
  TextField,
  Typography,
  Button,
  IconButton,
  InputAdornment,
  Tooltip,
  Avatar,
} from "@mui/material";
import PropTypes from "prop-types";
import LinearProgress from "@mui/material/LinearProgress";
import { useMutation } from "@apollo/client";
import moment from "moment";
//date picker
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

//Icons
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import EmptyImage from "../../Assets/empty-image.png";
import { AuthContext } from "../../Context/AuthContext";
// import { CREATE_USER } from "../../Schema/User";
import CropImageFile from "../CropImage/CropImageFile";
import { CREATE_SHARES } from "../../Schema/Shares";

export default function CreateShares({ open, handleClose, setRefetch }) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  //hiden and show vibility
  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const [createShare] = useMutation(CREATE_SHARES, {
    onCompleted: ({ createShare }) => {
      setLoading(false);
      // console.log("createShare::", createShare);
      if (createShare?.status) {
        setAlert(true, "success", createShare?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        // console.log("createShare::", createShare);
        setAlert(true, "error", createShare?.message);
      }
    },
    onError: (error) => {
      // console.log("createShare::", createShare);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  // const [mutateFunction] = useMutation(INCREMENT_COUNTER);

  const passwordRules = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  // min 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric digit.
  //fromik
  const CheckValidation = Yup.object().shape({
    endDate: Yup.date(),
    startDate: Yup.date(),
    title: Yup.string().required("ទាមទារ!"),
    titleKh: Yup.string().required("ទាមទារ!"),
    price: Yup.number().required("ទាមទារ!").min(1, "តម្លៃហ៊ុនត្រូវតែធំជាង០!"),
    totalShare: Yup.number()
      .required("ទាមទារ!")
      .min(1, "ចំនួនភាគហ៊ុនត្រូវតែធំជាង០!"),
    remark: Yup.string(),
  });
  const formik = useFormik({
    initialValues: {
      endDate: moment(),
      startDate: moment(),
      title: "",
      titleKh: "",
      price: 0,
      totalShare: 0,
      remark: "",
    },
    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      createShare({
        variables: {
          input: {
            endDate: moment(values?.endDate).format("YYYY-MM-DD"),
            startDate: moment(values?.startDate).format("YYYY-MM-DD"),
            title: values?.title,
            titleKh: values?.titleKh,
            price: values?.price,
            totalShare: values?.totalShare,
            remark: values?.remark,
          },
        },
      });
    },
  });

  const {
    errors,
    touched,
    handleSubmit,
    getFieldProps,
    resetForm,
    values,
    setFieldValue,
  } = formik;

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">បង្កើតភាគហ៊ុន</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">ចំណងជើងខ្មែរ</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="ចំណងជើងខ្មែរ"
                  {...getFieldProps("titleKh")}
                  error={Boolean(touched.titleKh && errors.titleKh)}
                  helperText={touched.titleKh && errors.titleKh}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">ចំណងជើងអង់គ្លេស</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="ចំណងជើងអង់គ្លេស"
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">ថ្ងៃចាប់ផ្ដើម</Typography>
                <LocalizationProvider
                  className="text-field"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    // value={dateOfBirth}
                    value={values?.startDate}
                    onChange={(newValue) => {
                      setFieldValue("startDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="text-field"
                        size="small"
                        {...params}
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">ថ្ងៃបញ្ចប់</Typography>
                <LocalizationProvider
                  className="text-field"
                  dateAdapter={AdapterDateFns}
                >
                  <DatePicker
                    // value={dateOfBirth}
                    value={values?.endDate}
                    onChange={(newValue) => {
                      setFieldValue("endDate", newValue);
                    }}
                    renderInput={(params) => (
                      <TextField
                        className="text-field"
                        size="small"
                        {...params}
                        type="date"
                        fullWidth
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>

              <Grid item xs={6}>
                <Typography className="field-title">ចំនួនភាគហ៊ុន</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  // placeholder="បញ្ជាក់ពាក្យសម្ងាត់"
                  type="number"
                  inputProps={{ min: 0 }}
                  {...getFieldProps("totalShare")}
                  error={Boolean(touched.totalShare && errors.totalShare)}
                  helperText={touched.totalShare && errors.totalShare}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className="field-title">តម្លៃ</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  // placeholder="បញ្ជាក់ពាក្យសម្ងាត់"
                  type="number"
                  inputProps={{ min: 0 }}
                  {...getFieldProps("price")}
                  error={Boolean(touched.price && errors.price)}
                  helperText={touched.price && errors.price}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography className="field-title">ចំណាំ</Typography>
                <TextField
                  fullWidth
                  size="small"
                  className="text-field"
                  placeholder="កំណត់ចំណាំ"
                  {...getFieldProps("remark")}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    បង្កើត​
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
