import React, { useContext, useState } from "react";
import { useFormik, Form, FormikProvider } from "formik";
import * as Yup from "yup";
//Dialog
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import {
  Grid,
  Stack,
  TextField,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import { useMutation } from "@apollo/client";
//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import { AuthContext } from "../../Context/AuthContext";
import { CREATE_POSITIONS } from "../../Schema/Positions";

export default function CreatePositions({ open, handleClose, setRefetch }) {
  const { setAlert } = useContext(AuthContext);
  const [loading, setLoading] = useState(false);

  const [createPosition] = useMutation(CREATE_POSITIONS, {
    onCompleted: ({ createPosition }) => {
      setLoading(false);
      // console.log("createPosition::", createPosition);
      if (createPosition?.status) {
        setAlert(true, "success", createPosition?.message);
        handleClose();
        resetForm();
        setRefetch();
      } else {
        // console.log("createPosition::", createPosition);
        setAlert(true, "error", createPosition?.message);
      }
    },
    onError: (error) => {
      // console.log("createPosition::", createPosition);
      setLoading(false);
      setAlert(true, "error", error?.message);
    },
  });

  //fromik
  const CheckValidation = Yup.object().shape({
    title: Yup.string().required("ទាមទារ!"),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
    },
    validationSchema: CheckValidation,
    onSubmit: async (values) => {
      // console.log("values:::", values);
      setLoading(true);
      createPosition({
        variables: {
          title: values?.title,
        },
      });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, resetForm, values } =
    formik;

  return (
    // Header
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="sm">
      <DialogTitle sx={{ padding: "10px 15px 10px 20px " }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">បង្កើតតួនាទី</Typography>
          </Stack>
          {/* close button */}
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>
      {/* Body of dialog */}
      <DialogContent dividers>
        <FormikProvider value={formik}>
          <Form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={3}>
              <Grid item xs={12}>
                <Typography className="field-title">តួនាទី</Typography>
                <TextField
                  className="text-field"
                  fullWidth
                  size="small"
                  placeholder="បង្កើតតួនាទី"
                  {...getFieldProps("title")}
                  error={Boolean(touched.title && errors.title)}
                  helperText={touched.title && errors.title}
                />
              </Grid>

              <Grid item xs={12}>
                {loading ? (
                  <Button className="btn-create" fullWidth>
                    កំពុងដំណើរការ...
                  </Button>
                ) : (
                  <Button
                    className="btn-create"
                    fullWidth
                    onClick={handleSubmit}
                  >
                    បង្កើតតួនាទី
                  </Button>
                )}
              </Grid>
            </Grid>
          </Form>
        </FormikProvider>
      </DialogContent>
    </Dialog>
  );
}
