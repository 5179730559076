import React, { useState } from "react";
import { Typography, Stack, Box, Button, Grid } from "@mui/material";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
//Icons
import CreateIcon from "../../Assets/create-icon.svg";
//Srcs
import "../../Style/pagestyle.scss";
import PersonalInfo from "./PersonalInformation/PersonalInfo";
import ShareholderValue from "./ShareholderValue";
import CreateSellingShares from "./Share/CreateSellingShares";

export default function ShareholderDetails() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const location = useLocation();
  // const params = new URLSearchParams(location.search);
  // const [shareholderId, setShareholderId] = useState(params.get("id"));
  // const [fullName, setFullName] = useState(params.get("fullName"));
  let editData = location?.state?.data;
  // console.log("editData::", editData);

  // ==================================== Set tab =======================================
  let tabValueFromLocal = JSON.parse(
    window.localStorage.getItem("tabValueFromLocal")
  );

  const [tabValue, setTabValue] = useState(
    tabValueFromLocal ? tabValueFromLocal : "PersonalInfo"
  );

  const handleTabPersonal = (e) => {
    setTabValue(e);
    window.localStorage.setItem("tabValueFromLocal", JSON.stringify(e));
  };

  const handleTabShareholderValue = (e) => {
    setTabValue(e);
    window.localStorage.setItem("tabValueFromLocal", JSON.stringify(e));
  };

  return (
    <div className="page-container">
      <Stack
        direction="row"
        spacing={2}
        className="page-header"
        justifyContent="space-between"
      >
        <Stack direction="row" justifyContent="center" spacing={2}>
          <Stack direction="column" justifyContent="center">
            <Box className="slash" />
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Link to="/shareholder" style={{ textDecoration: "none" }}>
              <Typography className="page-title">ម្ចាស់ភាគហ៊ុន</Typography>
            </Link>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">/</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <Typography className="page-title">
              {editData?.firstName + " " + editData?.lastName}
            </Typography>
          </Stack>
        </Stack>
        <Stack justifyContent="center">
          {tabValue === "ShareholderValue" ? (
            <Box sx={{ marginTop: "15px" }}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                  <Stack direction="row" justifyContent="right">
                    <Button
                      onClick={handleOpen}
                      className="btn-create"
                      startIcon={
                        <img
                          src={CreateIcon}
                          alt="CreateIcon"
                          className="icon-add"
                        />
                      }
                    >
                      បង្កើត
                    </Button>
                  </Stack>
                  <CreateSellingShares
                    open={open}
                    handleClose={handleClose}
                    editData={editData}
                    // setRefetch={refetch}
                  />
                </Grid>
              </Grid>
            </Box>
          ) : null}
        </Stack>
      </Stack>

      <Box
        sx={{
          bgcolor: "#fff",
          borderRadius: "6px",
          padding: "0px 20px 20px 20px",
        }}
      >
        <Box className="tabs-containner">
          <Stack direction="row" spacing={2}>
            <Button
              onClick={() => handleTabPersonal("PersonalInfo")}
              // onClickCapture={() => handleDisable("PersonalInfo")}
              className={
                tabValue === "PersonalInfo" ? "active-btn-tab" : "btn-tab"
              }
            >
              ព័ត៌មានផ្ទាល់ខ្លួន
            </Button>
            <Button
              onClick={() => handleTabShareholderValue("ShareholderValue")}
              className={
                tabValue === "ShareholderValue" ? "active-btn-tab" : "btn-tab"
              }
            >
              ភាគហ៊ុន
            </Button>
          </Stack>
        </Box>

        <Box className="box-container-mobile">
          <Box>
            {tabValue === "PersonalInfo" ? (
              <PersonalInfo status={"PersonalInfo"} editData={editData} />
            ) : null}
            {tabValue === "ShareholderValue" ? (
              <ShareholderValue
                status={"ShareholderValue"}
                editData={editData}
              />
            ) : null}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
