import React, { useState } from "react";
import { IconButton, Stack, Box, Tooltip } from "@mui/material";
//Icons
import { MdDelete } from "react-icons/md";
import { ImKey } from "react-icons/im";
//Srcs
import "../../Style/actionstyle.scss";
import DeleteUser from "./DeleteUser";
import ResetPassword from "./ResetPassword";

export default function UserAction({ editData, setRefetch }) {
  //Modal delete
  const [openDel, setOpenDel] = useState(false);
  const handleOpenDel = () => setOpenDel(true);
  const handleCloseDel = () => setOpenDel(false);

  //Modal reset
  const [openReset, setOpenReset] = useState(false);
  const handleOpenReset = () => setOpenReset(true);
  const handleCloseReset = () => setOpenReset(false);

  return (
    <div>
      <Stack direction="row" justifyContent="right">
        <Tooltip title="កែប្រែពាក្យសម្ងាត់" placement="top">
          <IconButton onClick={handleOpenReset}>
            <Box className="reset-container">
              <ImKey className="reset-icon" />
            </Box>
          </IconButton>
        </Tooltip>
        {/* <Tooltip title="កែប្រែ" placement="top">
          <IconButton onClick={handleOpenEdit}>
            <Box className="update-container">
              <TbEdit className="update-icon" />
            </Box>
          </IconButton>
        </Tooltip> */}
        <Tooltip title="លុប" placement="top">
          <IconButton onClick={handleOpenDel}>
            <Box className="delete-container">
              <MdDelete className="delete-icon" />
            </Box>
          </IconButton>
        </Tooltip>
      </Stack>

      <ResetPassword
        open={openReset}
        handleClose={handleCloseReset}
        editData={editData}
        setRefetch={setRefetch}
      />

      <DeleteUser
        open={openDel}
        handleClose={handleCloseDel}
        editData={editData}
        setRefetch={setRefetch}
      />
    </div>
  );
}
