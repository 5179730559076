import React, { useState, useContext, useEffect } from "react";
import { useRoutes } from "react-router-dom";
import { Navigate } from "react-router-dom";
import { useQuery } from "@apollo/client";
// layouts
// import Layout from "./Layout/LayoutOld";
import Layout from "./Layout/Layout";
//Page
import Login from "./Pages/Login";
import Users from "./Pages/Users";
import Page404 from "./Pages/Page404";
import Setting from "./Pages/Setting";
import { AuthContext } from "./Context/AuthContext";
import ForgotPassword from "./Pages/ForgotPassword";
//Query
import { GET_USER_LOGIN } from "./Schema/User";
import Shareholder from "./Pages/Shareholder";
import ShareholderDetails from "./Components/Shareholder/ShareholderDetails";
import Positions from "./Pages/Positions";
import Shares from "./Pages/Shares";

export default function Router({ prefersDarkMode, setPrefersDarkMode }) {
  const { state } = useContext(AuthContext);
  // =================== get user login ==================
  useQuery(GET_USER_LOGIN, {
    onCompleted: ({ getUserLogin }) => {
      // console.log("getUserLogin::", getUserLogin);
      window.localStorage.setItem(
        "userStatus",
        JSON.stringify(getUserLogin?.status)
      );
      if (getUserLogin?.status) {
        window.localStorage.setItem(
          "userLogin",
          JSON.stringify(getUserLogin.user)
        );
      }
    },
    fetchPolicy: "network-only",
  });

  // ====================== check state user login ==========================
  const [token, setToken] = useState(
    JSON.parse(window.localStorage.getItem("token"))
  );

  useEffect(() => {
    async function checkUser() {
      let userToke = await JSON.parse(window.localStorage.getItem("token"));
      if (userToke) {
        setToken(userToke);
        return;
      }

      if (state?.user?.token !== null && state?.user?.token !== undefined) {
        setToken(state?.user?.token);
        window.localStorage.setItem(
          "token",
          JSON.stringify(state?.user?.token)
        );
      } else {
        setToken(null);
      }
    }

    setTimeout(() => {
      checkUser();
    }, 2000);
  }, [state?.user]);

  //  ======================================= check Route ===================================
  const LoginPage = useRoutes([
    { path: "/", element: <Login /> },
    { path: "/login", element: <Login /> },
    { path: "forgot-password", element: <ForgotPassword /> },
    { path: "*", element: <Login /> },
  ]);

  const Content = useRoutes([
    {
      path: "/",
      element: (
        <Layout
          to="/dashboard"
          prefersDarkMode={prefersDarkMode}
          setPrefersDarkMode={setPrefersDarkMode}
        />
      ),
      children: [
        { path: "/", element: <Navigate to="/shareholder" /> },
        { path: "setting", element: <Setting /> },
        { path: "setting/user", element: <Users /> },
        { path: "setting/positions", element: <Positions /> },
        { path: "setting/shares", element: <Shares /> },
        { path: "shareholder", element: <Shareholder /> },
        {
          path: "shareholder/shareholder-details",
          element: <ShareholderDetails />,
        },
        { path: "*", element: <Page404 /> },
      ],
    },
  ]);

  if (token !== null && token !== undefined) {
    return Content;
  } else {
    return LoginPage;
  }
  // return Content;
}
