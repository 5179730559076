import React, { useState, useEffect } from "react";
// import "../../Style/pagestyle.scss";
import "./casereciecevocherprint.scss";
import symbol from "../../../Assets/symbol.jpg";
import logo from "../../../Assets/Go-Global-Full-Name.png";
import Brightness1OutlinedIcon from "@mui/icons-material/Brightness1Outlined";
import { Box, Stack, Grid, TextField, Typography } from "@mui/material";
import { ToWords } from "to-words";
import { GET_SELING_SHARE_DETAILS } from "../../../Schema/Shareholder";
import { useQuery } from "@apollo/client";
import { IoMdCheckmark } from "react-icons/io";

//src
import { currencyFormatKh, currencyFormat } from "../../../Function/DynamicFn";

export default function CaseRecieveVoucherPrint({ editData }) {
  const [indexValue, setIndexValue] = useState(0);
  const [data, setData] = useState();

  const { refetch } = useQuery(GET_SELING_SHARE_DETAILS, {
    variables: {
      id: editData?._id,
    },
    onCompleted: ({ getSellingShareDetail }) => {
      // console.log("getSellingShareDetail", getSellingShareDetail);
      setData(getSellingShareDetail);
    },
    onError: (err) => {
      console.log("Error::", err?.message);
    },
  });

  const toWords = new ToWords({
    localeCode: "en-US",
    converterOptions: {
      currency: false,
      ignoreDecimal: false,
      ignoreZeroCurrency: false,
      doNotAddOnly: true,
    },
  });

  //==================================Amount in word=========================
  const [showAmountInWord, setShowAmountInWord] = useState(false);
  const [amountInWord, setAmountInWord] = useState(
    JSON.parse(localStorage.getItem("amountInWordFromLocal"))
  );

  const handleShowAmountInWord = (name) => {
    setAmountInWord(name);
    localStorage.setItem("amountInWordFromLocal", JSON?.stringify(name));
  };

  return (
    <Box className="container">
      <Stack direction="column" align="center" justifyContent="center">
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Stack direction="column-reverse" height="100%">
              <img alt="Go-Global-Full-Name" className="image" src={logo} />
            </Stack>
          </Grid>
          <Grid item xs={6}>
            <Stack direction="column">
              <Typography className="header">ព្រះរាជាណាចក្រកម្ពុជា</Typography>
              <Typography className="headerEng">Kingdom Of Cambodia</Typography>
              <Typography className="header">
                ជាតិ សាសនា ព្រះមហាក្សត្រ
              </Typography>
              <Typography className="headerEng">
                Nation Religion King
              </Typography>
              <Typography>
                <img alt="symbol" className="imageSymbol" src={symbol} />
              </Typography>
            </Stack>
          </Grid>
          <Grid item xs={3}>
            <Stack direction="column-reverse" height="100%" alignItems="right">
              <Typography>លេខ/N : {editData?.numbering}</Typography>
            </Stack>
          </Grid>
        </Grid>
        <Grid>
          <Grid xs={12}>
            <Stack direction="column" alignItems="center">
              <Stack direction="column">
                <Typography className="header">
                  សក្ខីប័ណ្ណទទួលសាច់ប្រាក់ពីម្ចាស់ភាគហ៊ុន
                </Typography>
                <Typography className="headerEng">
                  Cash Recieving Vocher
                </Typography>
              </Stack>
              <Stack direction="row">
                <Typography className="header">{editData?.titleKh}/</Typography>
                <Typography className="headerEng">{editData?.title}</Typography>
              </Stack>
            </Stack>
          </Grid>
        </Grid>
      </Stack>
      <table className="table-container-main" style={{ marginTop: "10px" }}>
        <tr>
          <th colSpan={1} rowSpan={2} style={{ padding: "6px" }}>
            <div>កាលបរិច្ឆេទ</div>
            <div>Date</div>
          </th>
          <th colSpan={3} rowSpan={2} style={{ padding: "6px" }}>
            <div>ឈ្មោះសមាជិកហ៊ុន</div>
            <div>Shareholder's Name</div>
          </th>
          <th style={{ padding: "6px" }} rowSpan={2}>
            <div>ចំនួនហ៊ុន</div>
            <div>Share</div>
          </th>
          <th style={{ padding: "6px" }} rowSpan={2}>
            <div>តម្លៃហ៊ុន</div>
            <div>Price</div>
          </th>
          <th
            colSpan={2}
            style={{
              padding: "6px",
              borderBottom: "1px solid #81a8cf",
            }}
          >
            ទឹកប្រាក់ (Cash)
          </th>
        </tr>
        <tr>
          <th
            style={{
              borderTop: "1px solid #81a8cf",
            }}
          >
            Riels
          </th>
          <th
            style={{
              borderTop: "1px solid #81a8cf",
            }}
          >
            USD
          </th>
        </tr>

        {/* {[editData].map((row, index) => {
          return ( */}
        <tr>
          <td align="center">12-Aug-21</td>
          <td colSpan={3}>
            <Stack direction="row" spacing={1} style={{ padding: "6px" }}>
              <Stack direction="column" spacing={1} width="30%" align="right">
                <Typography className="font">ID៖ </Typography>
                <Typography className="font">ឈ្មោះ៖ </Typography>
                <Typography className="font">ទូរស័ព្ទ៖</Typography>
                <Typography className="font">អ៊ីម៉ែល៖</Typography>
              </Stack>
              <Stack direction="column" spacing={1} width="70%" align="left">
                <Typography className="font">{data?.nationalId}</Typography>
                <Typography className="font">
                  {" "}
                  {data?.firstName + " " + data?.lastName}
                </Typography>
                <Typography className="font">{data?.tel}</Typography>
                <Typography className="font">{data?.email}</Typography>
              </Stack>
            </Stack>
          </td>
          <td align="center">{data?.share}</td>
          <td align="center">{currencyFormatKh(data?.price)}</td>
          <td align="center">{currencyFormatKh(data?.priceKHR)}</td>
          <td align="center">{currencyFormat(data?.priceUSD)}</td>
        </tr>
        {/* );
        })} */}

        <tr>
          <td
            align="center"
            colSpan={8}
            style={{ padding: "6px" }}
          >
            <Stack direction="row" justifyContent="center">
              <Typography className="thead-text-dusburment">
                ចំនួនទឹកប្រាក់សរុបជាអក្សរ
              </Typography>
              <Stack direction="column" className="thead-text-dusburment">
                <Typography
                  className="thead-text-dusburment"
                  onClick={() => setShowAmountInWord(true)}
                  sx={{ cursor: "pointer" }}
                >
                  {amountInWord
                    ? `: ${amountInWord}`
                    : ": ចំនួនទឹកប្រាក់សរុបជាអក្សរ"}
                </Typography>
                {showAmountInWord ? (
                  <Typography className="thead-text-dusburment">
                    <TextField
                      className="thead-text-dusburment"
                      size="small"
                      sx={{ width: "100%" }}
                      onMouseLeave={() => setShowAmountInWord(false)}
                      value={amountInWord}
                      onChange={(e) => handleShowAmountInWord(e?.target?.value)}
                    />
                  </Typography>
                ) : null}
              </Stack>
            </Stack>
            <div>
              Total Amount in word:
              {" " +
                toWords.convert(
                  data?.priceKHR ? parseFloat(data?.priceKHR?.toFixed(2)) : 0
                ) +
                " "}{" "}
              {/* {" " + toWords.convert(parseFloat(10?.toFixed(2))) + " "} */}
              Riels Only
            </div>
          </td>
        </tr>
      </table>

      <Stack direction="row">
        <Stack width="50%">
          <table
            className="table-container-main1"
            style={{
              borderLeft:
                "2px solid #81a8cf",
            }}
          >
            <tr>
              <th colSpan={3} style={{ width: "25%", padding: "6px" }}>
                សរុបសាច់ប្រាក់/Total Cash
              </th>
              <th
                style={{
                  width: "25%",
                  padding: "6px",
                  borderRight: "2px solid #81a8cf",
                  color: "red",
                }}
                align="right"
              >
                0៛
              </th>
            </tr>

            {data?.totalCashKHR?.map((data, index1) => {
              return (
                <tr
                  key={index1}
                  style={{
                    borderBottom: "1px solid #fff",
                    borderRight: "2px solid #81a8cf"
                  }}
                >
                  <td align="center" width="10%">
                    {data?.cash}
                  </td>
                  <td width="5%" align="center">
                    *
                  </td>
                  <td align="center" width="10%">
                    {data?.qty}
                  </td>
                  <td align="right" width="27%">
                    {currencyFormatKh(data?.total)}
                  </td>
                </tr>
              );
            })}
          </table>
        </Stack>
        <Stack width="50%">
          <table
            className="table-container-main1"
            style={{ borderRight: "2px solid #81a8cf" }}
          >
            <tr>
              <th
                colSpan={3}
                style={{
                  width: "25%",
                  padding: "6px",
                  borderLeft: "0px solid #81a8cf",
                }}
              >
                សរុបសាច់ប្រាក់/Total Cash
              </th>
              <th
                style={{
                  width: "25%",
                  padding: "6px",
                }}
                align="right"
              >
                <Stack direction="row">
                  <Typography
                    width="50%"
                    sx={{ color: "red", textAlign: "left", fontSize: "12px" }}
                  >
                    $
                  </Typography>
                  <Typography
                    width="50%"
                    sx={{ color: "red", textAlign: "center", fontSize: "12px" }}
                  >
                    -
                  </Typography>
                </Stack>
              </th>
            </tr>

            {data?.totalCashUSD?.map((data, index2) => {
              return (
                <tr
                  key={index2}
                  style={{
                    borderBottom: "1px solid #fff",
                    borderLeft: "0px solid #81a8cf",
                  }}
                >
                  <td
                    align="center"
                    width="10%"
                    style={{
                      borderLeft: "0px solid #81a8cf",
                    }}
                  >
                    {data?.cash}
                  </td>
                  <td width="5%" align="center">
                    *
                  </td>
                  <td align="center" width="10%">
                    {data?.qty}
                  </td>
                  <td align="right" width="27%" padding>
                    <Stack direction="row" paddingLeft="6px">
                      <Typography
                        width="50%"
                        sx={{ textAlign: "left", fontSize: "12px" }}
                      >
                        $
                      </Typography>
                      <Typography
                        width="50%"
                        sx={{ textAlign: "center", fontSize: "12px" }}
                      >
                        {data?.total ? data?.total.toFixed(2) : data?.total}
                      </Typography>
                    </Stack>
                  </td>
                </tr>
              );
            })}
          </table>
        </Stack>
      </Stack>

      <Stack direction="row" mt={2} spacing={2} bgcolor="#D2E2F2">
        <Typography className="font" style={{ fontWeight: "bold" }}>
          សម្គាល់ ៖
        </Typography>
        <Stack direction="row" spacing={5} justifyContent="center">
          <Stack direction="column" spacing={2}>
            <Typography className="font">
              1. ការបង់ប្រាក់ទៅបេឡាករផ្ទាល់
            </Typography>
            <Typography className="font">
              2. ការបង់ប្រាក់តាមគណនីធនាគារ
            </Typography>
            <Typography className="font">
              3. ការបង់ប្រាក់តាមរយៈមូលប្បទានប័ត្រ
            </Typography>
          </Stack>
          <Stack direction="column" spacing={1.5} justifyContent="center">
            {/* <Brightness1OutlinedIcon fontSize="medium" className="icon" /> */}
            <Box sx={{ position: "relative" }}>
              <Box className="cicle-box" />
              {data?.note === "ការបង់ប្រាក់ទៅបេឡាករផ្ទាល់" ? (
                <IoMdCheckmark
                  style={{
                    position: "absolute",
                    top: "1px",
                    left: "4px",
                  }}
                  className="icon-check"
                />
              ) : null}
            </Box>
            <Box sx={{ position: "relative" }}>
              <Box className="cicle-box" />
              {data?.note === "ការបង់ប្រាក់តាមគណនីធនាគារ" ? (
                <IoMdCheckmark
                  style={{
                    position: "absolute",
                    top: "1px",
                    left: "4px",
                  }}
                  className="icon-check"
                />
              ) : null}
            </Box>
            <Box sx={{ position: "relative" }}>
              <Box className="cicle-box" />
              {data?.note === "ការបង់ប្រាក់តាមរយៈមូលប្បទានប័ត្រ" ? (
                <IoMdCheckmark
                  style={{
                    position: "absolute",
                    top: "1px",
                    left: "4px",
                  }}
                  className="icon-check"
                />
              ) : null}
            </Box>
          </Stack>
        </Stack>
      </Stack>

      <Stack
        mt={2}
        direction="row"
        justifyContent="space-between"
        style={{ margin: "30px" }}
      >
        <Stack spacing={6} justifyContent="center">
          <Typography className="fontKh">ម្ចាស់ភាគហ៊ុន/SHAREHOLDER</Typography>
          <Stack direction="column" align="center" spacing={1}>
            <Typography>....................</Typography>
            <Typography className="font">
              កាលបរិច្ឆេទ/Date:..............
            </Typography>
          </Stack>
        </Stack>
        <Stack spacing={6} align="center">
          <Typography className="fontKh">បេឡាករ/CASHIER</Typography>
          <Stack direction="column" align="center" spacing={1}>
            <Typography>....................</Typography>
            <Typography className="font">
              កាលបរិច្ឆេទ/Date:..............
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
}
