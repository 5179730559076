import { useState, useEffect } from "react";
import { useQuery } from "@apollo/client";
import { Autocomplete, TextField } from "@mui/material";
import { GET_USER_WITH_PAGINATION } from "../../Schema/User";
import { SELECT_INVITE_FOR_SELECT } from "../../Schema/SchemaForSelect";
import { GET_SHAREHOLDER_PAGINATION } from "../../Schema/Shareholder";
import { GET_SHARES_PAGINATOR } from "../../Schema/Shares";
import { GET_POSITIONS } from "../../Schema/Positions";

export function SelectUser({ selectedVal, setSelectedVal, className }) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  // console.log("selectedVal", selectedVal);

  const { refetch } = useQuery(GET_USER_WITH_PAGINATION, {
    variables: {
      page: 1,
      role: "All",
      keyword: "",
      limit: 200,
    },
    onCompleted: ({ getUsersPagination }) => {
      if (getUsersPagination) {
        // console.log("getUsersPagination", getUsersPagination);
        const Data = getUsersPagination?.data?.map((e) => {
          return {
            id: e?._id,
            title: e?.lastName + " " + e?.firstName,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើស" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើស"}
        />
      )}
    />
  );
}

export function SelectInvite({ selectedVal, setSelectedVal, className }) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  // console.log("selectedVal", selectedVal);

  const { refetch } = useQuery(SELECT_INVITE_FOR_SELECT, {
    onCompleted: ({ getInvitesForSelect }) => {
      if (getInvitesForSelect) {
        // console.log("getInvitesForSelect", getInvitesForSelect);
        const Data = getInvitesForSelect?.map((e) => {
          return {
            id: e?._id,
            title: e?.name,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើស" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើស"}
        />
      )}
    />
  );
}

export function SelectShare({ selectedVal, setSelectedVal, className }) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  // console.log("selectedVal", selectedVal);

  const { refetch } = useQuery(GET_SHARES_PAGINATOR, {
    variables: {
      page: 1,
      limit: 10,
      keyword: "",
    },
    onCompleted: ({ getSharesPaginator }) => {
      if (getSharesPaginator) {
        // console.log("getSharesPaginator++++", getSharesPaginator);
        const Data = getSharesPaginator?.data?.map((e) => {
          return {
            id: e?._id,
            title: e?.title,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើស" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField {...params} size="small" placeholder={"ជ្រើសរើស"} />
      )}
    />
  );
}
//SELECT NOTIFY
export function SelectShareholder({ selectedVal, setSelectedVal, className }) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  // console.log("selectedVal", selectedVal);

  const { refetch } = useQuery(GET_SHAREHOLDER_PAGINATION, {
    variables: {
      page: 1,
      limit: 10,
      position: "ទាំងអស់",
      keyword: "",
    },
    onCompleted: ({ getShareholdersPagination }) => {
      if (getShareholdersPagination) {
        // console.log("getShareholdersPagination +++", getShareholdersPagination);
        const Data = getShareholdersPagination?.data?.map((e) => {
          return {
            id: e?._id,
            title: e?.lastName + " " + e?.firstName,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើស" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          placeholder={"ជ្រើសរើស"}
        />
      )}
    />
  );
}
//SELECT POSITION
export function SelectPosition({ selectedVal, setSelectedVal, className }) {
  //Change language
  const [selectOptions, setSelectOptions] = useState([]);
  const [checkRefetch, setCheckRefetch] = useState(false);
  // console.log("selectedVal", selectedVal);

  const { refetch } = useQuery(GET_POSITIONS, {
    variables: {
      keyword: "",
    },
    onCompleted: ({ getPositions }) => {
      if (getPositions) {
        // console.log("getPositions", getPositions);
        const Data = getPositions?.map((e) => {
          return {
            id: e?._id,
            title: e?.title,
          };
        });
        setSelectOptions(Data);
      }
    },
    onError: (error) => {
      console.log(error?.message);
    },
  });
  const handleSelect = (val) => {
    setSelectedVal(val);
    setCheckRefetch(!checkRefetch);
  };

  useEffect(() => {
    refetch();
  }, [checkRefetch]);

  return (
    <Autocomplete
      style={{ fontFamily: "KantumruyPro" }}
      disablePortal
      value={selectedVal}
      options={selectOptions}
      getOptionLabel={(option) =>
        option?.title === undefined ? "ជ្រើសរើស" : option?.title
      }
      isOptionEqualToValue={(option, value) => option?.value === value?.value}
      onChange={(event, val) => handleSelect(val)}
      renderInput={(params) => (
        <TextField
          className={className}
          {...params}
          size="small"
          style={{ fontFamily: "KantumruyPro" }}
          placeholder={"ជ្រើសរើស"}
        />
      )}
    />
  );
}
