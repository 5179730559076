import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser(
    $profileImage: String!
    $userName: String!
    $email: String!
    $password: String!
  ) {
    createUser(
      profileImage: $profileImage
      userName: $userName
      email: $email
      password: $password
    ) {
      status
      message
    }
  }
`;

export const GET_USER_WITH_PAGINATION = gql`
  query GetUsersPagination($page: Int!, $limit: Int!, $keyword: String!) {
    getUsersPagination(page: $page, limit: $limit, keyword: $keyword) {
      data {
        _id
        userName
        profileImage
        email
        role
      }
      paginator {
        slNo
        prev
        next
        perPage
        totalPosts
        totalPages
        currentPage
        hasPrevPage
        hasNextPage
        totalDocs
      }
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(_id: $id) {
      status
      message
    }
  }
`;

export const RESET_USER_PASSWORD = gql`
  mutation ResetPassword($id: ID!, $newPassword: String!) {
    resetPassword(_id: $id, newPassword: $newPassword) {
      status
      message
    }
  }
`;

export const GET_USER_LOGIN = gql`
  query GetUserLogin {
    getUserLogin {
      user {
        _id
        userName
        profileImage
        email
        role
      }
      token
      status
      message
    }
  }
`;

export const USER_LOGIN = gql`
  mutation UserLogin($email: String!, $password: String!) {
    userLogin(email: $email, password: $password) {
      user {
        _id
        userName
        profileImage
        email
        role
      }
      token
      status
      message
    }
  }
`;
