import React, { useContext, useState, useRef } from "react";

import {
  Grid,
  Stack,
  Box,
  Typography,
  Button,
  IconButton,
  DialogContent,
  DialogTitle,
  Dialog,
} from "@mui/material";

//Icons
import { BiMinusCircle } from "react-icons/bi";
//Src
import "../../Style/dialogstyle.scss";
import CaseRecieveVoucherPrint from "./Share/CaseRecieveVoucherPrint";
import { useReactToPrint } from "react-to-print";
const ShareholderForm = ({ open, handleClose, editData }) => {
  const [loading, setLoading] = useState(false);

  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  return (
    <Dialog open={open} className="dialog-container" fullWidth maxWidth="lg">
      <DialogTitle sx={{ padding: "10px 20px" }}>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="center">
            <Typography className="dialog-title">ព្រីនឯកសារ</Typography>
          </Stack>
          <Stack direction="column" justifyContent="center">
            <IconButton onClick={handleClose}>
              <BiMinusCircle className="close-icon" />
            </IconButton>
          </Stack>
        </Stack>
      </DialogTitle>

      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack ref={componentRef}>
              <CaseRecieveVoucherPrint editData={editData} />
            </Stack>
          </Grid>

          <Grid item xs={12}>
            {loading ? (
              <Button className="btn-create" fullWidth>
                កំពុងដំណើរការ...
              </Button>
            ) : (
              <Button className="btn-create" fullWidth onClick={handlePrint}>
                Print
              </Button>
            )}
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

export default ShareholderForm;
